import { doc, getDoc } from "firebase/firestore";
import * as React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

import { app, db } from "../../firebase";

import { getAuth } from "firebase/auth";
import { UserContext } from "../../UserState";
import Navbar, {
  MobileBottomTabBar,
  signInWithGoogleHandler,
} from "../../components/Navbar";
import {
  formatBookAuthor,
  getBookCondition,
  MarketItem,
  Organization,
  SchoolTermCacheTwoCourseData,
  SchoolTermCacheTwoDocData,
  TextbookMarketItem,
  UserDoc,
  UserDocSchoolTermData,
} from "../../models";

import DeleteIcon from "@mui/icons-material/Delete";
import { findAllByTestId } from "@testing-library/react";
import AddYourCourses from "./AddYourCourses";
import CoursesAlreadyAdded from "./CoursesAlreadyAdded";
import { AppContext } from "../../AppState";
import { TextbookInfoMarketRowItem } from "../market/MarketPage";
import { shareTextbookPostLink } from "../market/MarketItemPage";

interface HomePageData {
  orgs: Organization[];
}

export const homePageLoader = async () => {
  // const uid = getAuth(app).currentUser?.uid;
  // if (!uid) return redirect("/");
};

export const NeedToSignIn = () => {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2>Sign In First</h2>
          <p>
            You must sign in with your Google account before you can access this
            page.
          </p>
          <div className="d-flex flex-row mt-2">
            <button
              className="btn btn-primary flex-fill"
              onClick={signInWithGoogleHandler}
            >
              Sign In with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getTimeDiff = (
  a: Date,
  b: Date
): { days: number; hours: number; minutes: number } => {
  const aTime = a.getTime();
  const bTime = b.getTime();

  const milisLeft = aTime - bTime;
  const MILIS_PER_DAY = 1000 * 60 * 60 * 24;
  const MILIS_PER_HOUR = 1000 * 60 * 60;
  const MILIS_PER_MINUTE = 1000 * 60;
  const daysLeft = Math.floor(milisLeft / MILIS_PER_DAY);
  const hoursLeft = Math.floor(
    (milisLeft - daysLeft * MILIS_PER_DAY) / MILIS_PER_HOUR
  );
  const minutesLeft = Math.floor(
    (milisLeft - (daysLeft * MILIS_PER_DAY + hoursLeft * MILIS_PER_HOUR)) /
      MILIS_PER_MINUTE
  );

  return {
    days: daysLeft,
    hours: hoursLeft,
    minutes: minutesLeft,
  };
};

export const getDurationText = (
  a: Date,
  b: Date,
  expandedLabels: boolean,
  includedLabels?: ["days" | null, "hours" | null, "minutes" | null],
  withComma?: boolean
): string | null => {
  const diff = getTimeDiff(a, b);

  let timeAgoText = "";
  const unitText = {
    days: ["days", "day", "days", "day"],
    hours: ["hours", "hour", "hrs", "hr"],
    minutes: ["minutes", "minute", "mins", "min"],
  };

  const usedLabels = includedLabels
    ? includedLabels
    : ["days", "hours", "minutes"];

  usedLabels.forEach((unit, i) => {
    if (!unit) return;
    const val = diff[unit as keyof typeof diff];
    const texts: string[] = unitText[unit as keyof typeof unitText];

    let label = "";
    if (val === 1) {
      label = expandedLabels ? texts[1] : texts[3];
    } else if (val > 1) {
      label = expandedLabels ? texts[0] : texts[2];
    }

    if (val >= 1) {
      timeAgoText += `${val} ${label}`;
    }
    if (val >= 1 && i < 2) {
      timeAgoText += withComma ? ", " : "  ";
    }
  });

  if (timeAgoText === "") {
    return null;
  }

  return timeAgoText;
};

export const GiftCardGiveawayCard = () => {
  const giveawayEnds = new Date("December 16, 2022 23:59:59");
  const now = new Date();

  const { days, hours, minutes } = getTimeDiff(giveawayEnds, now);

  return Date.now() < giveawayEnds.getTime() ? (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title fw-bold">
          Post a Textbook now to be entered to win $100 Amazon gift card
        </h4>
        <p className="mb-2">
          Sellers who post at least one textbook before midnight on{" "}
          <span className="fw-bold" style={{ whiteSpace: "nowrap" }}>
            Friday, Dec 16, 2021
          </span>{" "}
          are entered to win a{" "}
          <span className="fw-bold">$100 Amazon gift card.</span> The card
          details will be emailed to the winner's Plaza account email.
        </p>
        <div className="bg-dark rounded text-white p-2 text-center">
          {days} days, {hours} hours, {minutes} minutes left
        </div>
      </div>
    </div>
  ) : (
    <span></span>
  );
};

interface TextbooksForYourClassesProps {
  userDoc: UserDoc;
}

const TextbooksForYourClasses = ({ userDoc }: TextbooksForYourClassesProps) => {
  return (
    <div>
      <div className="card mt-3">
        <div className="card-body">
          <div></div>
        </div>
      </div>
    </div>
  );
};

const getUserBooks = (userID: string, books: MarketItem[]) => {
  return books
    .filter((item) => {
      return item.itemType === "textbooks" && item.sellerUID == userID;
    })
    .map((item) => item as TextbookMarketItem);
};

export const sharePlazaWebsiteLink = () => {
  const hostname = window.location.hostname;
  if (navigator.share) {
    navigator
      .share({
        // title: "web.dev",
        // text: "Check out web.dev.",
        url: `https://${hostname}/`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
};

const MarketItemsYouListed = () => {
  const { loadUserMarketItems, marketItems } = React.useContext(AppContext);

  const { userDoc } = React.useContext(UserContext);

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (userDoc && userDoc.schoolID && userDoc.UID) {
      console.log("loading user market items", userDoc);
      loadUserMarketItems(userDoc.schoolID, userDoc.UID).then(() => {
        setLoading(false);
      });
    }
  }, [userDoc]);

  if (userDoc) console.log("hello", marketItems, marketItems[userDoc.schoolID]);

  if (!userDoc) {
    return <span></span>;
  } else if (loading) {
    return (
      <div>
        <h4>Textbooks You've Posted</h4>
        <p className="text-muted mb-0">Loading...</p>
      </div>
    );
  } else if (
    !marketItems[userDoc.schoolID] ||
    getUserBooks(userDoc.UID, marketItems[userDoc.schoolID]).length === 0
  ) {
    return (
      <div className="pb-3">
        <h4>Textbooks You've Posted</h4>
        <p className="text-muted">Nothing so far</p>
        <div className="d-flex flex-row">
          <Link
            className="btn btn-primary flex-fill"
            to="/market/post"
            state={{ showBack: true }}
          >
            Post a Textbook
          </Link>
        </div>
      </div>
    );
  }

  const books = getUserBooks(userDoc.UID, marketItems[userDoc.schoolID]);
  const booksSorted = books.sort((a, b) => {
    return b.postedDate.getTime() - a.postedDate.getTime();
  });

  return (
    <div>
      <div className="d-flex flex-row mb-3">
        <Link className="btn btn-primary flex-fill" to="/">
          Go to landing page
        </Link>

        <button
          className="btn btn-outline-primary flex-fill ms-2 d-lg-none"
          onClick={sharePlazaWebsiteLink}
        >
          Share Plaza website link
        </button>
      </div>
      <div className="flex-column mb-4">
        <p className="h5">Share your posts on social media:</p>
        <p className="h6 text-muted mb-3">
          Tag us <span className="fw-bold">@plazaplatform</span>
        </p>
        <div>
          <a
            className="btn btn-light border me-2"
            href="https://www.instagram.com/plazaplatform/"
          >
            Instagram
          </a>
          <a
            className="btn btn-dark me-2"
            href="https://www.tiktok.com/@plazaplatform"
          >
            Tiktok
          </a>
          <a
            className="btn btn-danger me-2"
            href="https://m.youtube.com/@plazaplatform"
          >
            Youtube
          </a>
          <a
            className="btn btn-primary me-2"
            href="https://twitter.com/plazaplatform"
          >
            Twitter
          </a>
          {/* <div className="btn btn-warning mt-2 mt-sm-0">
            Snapchat: @plazaplatform
          </div> */}
        </div>
      </div>
      <h4>Textbooks You've Posted ({books.length})</h4>
      <div>
        <div>
          {booksSorted.map((book) => {
            const now = new Date();
            const timeAgoText = getDurationText(now, book.postedDate, true);

            let updatedAgoText;
            if (
              book.updatedDates &&
              book.updatedDates.length > 0 &&
              book.updatedDates[book.updatedDates.length - 1]
            ) {
              console.log(
                "updatedDates",
                book.updatedDates,
                book.updatedDates[book.updatedDates.length - 1]
              );
              updatedAgoText = getDurationText(
                now,
                book.updatedDates[book.updatedDates.length - 1],
                true
              );
            }

            return (
              <div
                className="card mb-2"
                onClick={() => {
                  navigate(`/market/${book.marketItemID}`, {
                    state: { showBack: true },
                  });
                }}
                style={{ cursor: "pointer" }}
                key={book.marketItemID}
              >
                <div className="card-body">
                  {book.textbookData && (
                    <div className="mb-2 d-flex flex-column align-items-start flex-sm-row align-items-sm-center justify-content-between">
                      <div className="pe-sm-2">
                        <p className="h6 fw-bold mb-0">
                          {book.textbookData.itemTitle}
                        </p>
                        <p className="text-muted mb-0">
                          {formatBookAuthor(book.textbookData.itemAuthor)}
                        </p>
                      </div>
                      <div className="my-2 my-sm-0 d-flex flex-row justify-content-between">
                        <button
                          className="btn btn-dark me-2 text-decoration-none"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/market/${book.marketItemID}/edit`, {
                              state: { showBack: true },
                            });
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-primary text-decoration-none d-md-none"
                          onClick={(e) => {
                            e.stopPropagation();
                            shareTextbookPostLink(book.marketItemID);
                          }}
                        >
                          Share
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="d-flex flex-row justify-content-between">
                    <p className="d-inline-block mb-0 me-2 h6">
                      ${book.price} •{" "}
                      {getBookCondition(book.bookCondition).name}{" "}
                      <span className="text-muted">
                        • {timeAgoText ? timeAgoText + " ago" : "Just Now"}
                      </span>
                      {updatedAgoText && (
                        <span className="text-muted">
                          {" "}
                          • Updated{" "}
                          {updatedAgoText
                            ? updatedAgoText + " ago"
                            : "Just Now"}{" "}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const { signedIn, userDoc } = React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = "Home - Plaza";
  }, []);

  if (!userDoc) {
    //TODO log this event with analytics, would be good stat to have on
    //how many visits to the homepage without signing in we have
    return (
      <div>
        <Navbar />
        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>
        <NeedToSignIn />
      </div>
    );
  }

  const { terms } = userDoc;

  let allCourses: any[] = [];
  userDoc.terms.forEach((t) => {
    allCourses = [...allCourses, ...t.courses];
  });

  return (
    <div>
      <Navbar />
      <div className="fixed-bottom border-top">
        <MobileBottomTabBar />
      </div>
      <div className="container" style={{ marginBottom: "80px" }}>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="pt-3">
              <h4>Your Courses</h4>
              <AddYourCourses userDoc={userDoc} />

              {allCourses.length > 0 ? (
                <div className="mt-4">
                  <CoursesAlreadyAdded
                    terms={terms as UserDocSchoolTermData[]}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="pt-3 mt-3 mt-lg-0">
              <MarketItemsYouListed />
              <div className="mt-4">
                <GiftCardGiveawayCard />
              </div>
              {/* <TextbooksForYourClasses userDoc={userDoc} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
