import React from "react";
import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { UserContext } from "../UserState";

import { app, db } from "../firebase";
import { arrayUnion, doc, setDoc } from "firebase/firestore";

import GoogleIcon from "@mui/icons-material/Google";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";

interface NavbarProps {
  isLanding: boolean;
  currentRouteID: string;
}

const provider = new GoogleAuthProvider();

export const signInWithGoogleHandler = () => {
  const auth = getAuth(app);
  signInWithRedirect(auth, provider).then(() => {});
};

const getTrackingID = () => {
  const id = localStorage.getItem("visitor");

  if (!id) {
    const newID = uuidv4();
    localStorage.setItem("visitor", newID);
    return newID;
  } else {
    return id;
  }
};

const saveTrackedIDVisit = (url: string) => {
  const trackedID = getTrackingID();
  const visitDoc = doc(db, `trackedVisits/${trackedID}`);

  const nowDate = new Date();
  setDoc(
    visitDoc,
    {
      visits: arrayUnion({
        date: nowDate,
        route: url,
      }),
    },
    { merge: true }
  );
};

export const LandingNavbar = () => {
  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();

  const location = useLocation();

  React.useEffect(() => {
    saveTrackedIDVisit(location.pathname);
    console.log("path", location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    const auth = getAuth(app);
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          navigate("/home");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <nav className="navbar bg-white fixed-top border-bottom pt-0">
      <div className="container-fluid bg-dark text-white py-2 mb-2">
        Plaza iOS and Android Apps coming soon!
      </div>
      <div
        className="container-fluid d-flex justify-content-between align-items-center"
        // style={{ paddingTop: "0.4rem", paddingBottom: "0.4rem" }}
      >
        <div>
          <span className="fw-bold mb-0 h5 me-2">Plaza</span>
          <span className="fw-bold mb-0 h5 text-muted">Texas A&M</span>
        </div>
        <div>
          {userContext.signedIn ? (
            <Link className="btn btn-primary" to="/home">
              Home
            </Link>
          ) : (
            <button
              className="btn btn-primary"
              onClick={signInWithGoogleHandler}
            >
              Sign In
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

const NAV_ITEMS = [
  {
    name: "Home",
    linkTo: "/home",
    tabName: "home",
    icon: <HomeIcon />,
  },
  {
    name: "Textbooks",
    linkTo: "/market",
    tabName: "market",
    icon: <LibraryBooksIcon />,
  },
  {
    name: "Profile",
    linkTo: "/profile",
    tabName: "profile",
    icon: <AccountCircleIcon />,
  },
];

const getNavbarTab = (pathname: string) => {
  const parts = pathname.split("/");
  const firstPart = parts[1];

  const results = NAV_ITEMS.filter((item) => item.tabName === firstPart);

  if (results.length !== 1) throw "No Tab Found";
  return results[0];
};

const navbarTabIsActive = (tabName: string, currPathname: string) => {
  return tabName === currPathname.replace("/", "");
  // const firstPart = currPathname.split("/")[1];
  // return tabName === firstPart;
};

const DesktopNavbar = () => {
  const { signedIn } = React.useContext(UserContext);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  let signedInLink = <span></span>;
  let signInWithGoogleText = "Sign In with Google";
  let backBanner = <span></span>;
  if (pathname === "/market") {
    signedInLink = (
      <Link
        className="btn btn-primary"
        to="/market/post"
        state={{ showBack: true }}
      >
        Post a Textbook
      </Link>
    );
    signInWithGoogleText = "Sign In with Google to Sell";
  }

  React.useEffect(() => {
    const auth = getAuth(app);
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          navigate("/home");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <nav className="navbar bg-white navbar-expand-sm">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <div>
            <Link to="/" className="text-decoration-none text-dark">
              <span className="fw-bold mb-0 h5 me-2">Plaza</span>
            </Link>
            <span className="fw-bold mb-0 h5 text-muted">Texas A&M</span>
          </div>

          <ul className="d-flex navbar-nav flex-row ms-4">
            {NAV_ITEMS.map((navItem) => {
              const isActive = navbarTabIsActive(navItem.tabName, pathname);

              return (
                <li
                  // className={`nav-item border rounded ${
                  //   isActive ? "bg-light" : "border-white"
                  // }`}
                  // className={`nav-item border-bottom border-2 ${
                  //   isActive ? "border-dark" : "border-white"
                  // }`}
                  className={`nav-item me-md-2 ${
                    isActive ? "bg-dark rounded" : ""
                  }`}
                  key={navItem.tabName}
                >
                  <Link
                    className={`nav-link ${
                      isActive ? "active text-white" : ""
                    }`}
                    to={navItem.linkTo}
                  >
                    {navItem.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          {signedIn ? (
            signedInLink
          ) : (
            <button
              className="btn btn-primary"
              onClick={signInWithGoogleHandler}
            >
              {signInWithGoogleText}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

interface MobileNavbarProps {
  overrideTitle?: string;
  marketPostTextbookButtonClick?: () => void;
  isPostingMarketItem?: boolean;
}

export const BackBanner = ({ text }: { text: string }) => {
  const navigate = useNavigate();
  return (
    <div
      className="container-fluid bg-dark text-white py-3 d-flex flex-row align-items-center"
      onClick={() => navigate(-1)}
      style={{ cursor: "pointer" }}
    >
      <ArrowBackIcon />
      <span className="ms-3 fw-bold">{text}</span>
    </div>
  );
};

export const MobileNavbar = ({
  overrideTitle,
  marketPostTextbookButtonClick,
  isPostingMarketItem,
}: MobileNavbarProps) => {
  const { signedIn } = React.useContext(UserContext);
  const { pathname, state } = useLocation();
  const urlParams = useParams();

  const currTab = getNavbarTab(pathname);
  const navigate = useNavigate();

  React.useEffect(() => {
    const auth = getAuth(app);
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          navigate("/home");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let signedInLink = <span></span>;
  let subtitle = <span></span>;
  let backBanner = <span></span>;

  if (pathname === "/market") {
    signedInLink = (
      <Link
        className="btn btn-primary"
        to="/market/post"
        state={{ showBack: true }}
      >
        Post a Textbook
      </Link>
    );
    subtitle = <p className="mb-0 text-muted">For Purchase Spring 2023</p>;
  } else if (pathname === "/market/post") {
    console.log("disabled", isPostingMarketItem);
    signedInLink = (
      <button
        className="btn btn-primary shadow-sm"
        onClick={marketPostTextbookButtonClick}
        disabled={isPostingMarketItem}
      >
        Post Textbook
      </button>
    );
    subtitle = <p className="mb-0 text-muted">for Spring 2023</p>;
    backBanner = <BackBanner text="Go Back" />;
  } else if (urlParams.marketItemID && state?.showBack) {
    backBanner = <BackBanner text="Go Back" />;
  }

  return (
    <div>
      {backBanner}
      <nav className="navbar">
        <div className="container-fluid pt-3 justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-start">
            <h1 className="fw-bold mb-0">{overrideTitle || currTab.name}</h1>
            {subtitle}
          </div>

          <div>
            {signedIn ? (
              signedInLink
            ) : (
              <button
                className="btn btn-primary"
                onClick={signInWithGoogleHandler}
              >
                Sign In to Sell
              </button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

const Navbar = (mobileProps: MobileNavbarProps) => {
  const location = useLocation();

  React.useEffect(() => {
    saveTrackedIDVisit(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <div className="d-block d-sm-none">
        <MobileNavbar {...mobileProps} />
      </div>
      <div className="d-none d-sm-block">
        <DesktopNavbar />
      </div>
    </div>
  );
};

export const MobileBottomTabBar = () => {
  const { pathname } = useLocation();
  return (
    <div className="d-block d-sm-none bg-white py-2">
      <div className="d-flex justify-content-around align-items-center">
        {NAV_ITEMS.map((navItem) => {
          const isActive = navbarTabIsActive(navItem.tabName, pathname);

          return (
            <Link
              to={navItem.linkTo}
              className="text-decoration-none"
              key={navItem.tabName}
            >
              <div
                className={`rounded p-2 d-flex flex-row align-items-center ${
                  isActive ? "bg-dark text-white" : "text-dark"
                }`}
              >
                {navItem.icon}
                <span className="ms-2">{navItem.name}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Navbar;

// const Navbar = ({ isLanding, currentRouteID }: NavbarProps) => {
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     const auth = getAuth(app);
//     getRedirectResult(auth)
//       .then((result) => {
//         if (result) {
//           navigate("/home");
//         }
//       })
//       .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         const email = error.customData.email;
//         const credential = GoogleAuthProvider.credentialFromError(error);
//       });
//   }, []);

//   // if (isLanding) return <LandingNavbar />;

//   return (
//     <UserContext.Consumer>
//       {(userData) => {
//         const isAuthenticated = userData.userDoc?.name !== undefined;

//         return (
//           <nav
//             className={`navbar ${
//               isLanding
//                 ? "fixed-top navbar-dark"
//                 : "bg-white navbar-expand-sm border-bottom shadow-sm"
//             }`}
//           >
//             <div className="container-fluid">
//               {isLanding ? (
//                 <span className="navbar-brand fw-bold py-0">Plaza</span>
//               ) : (
//                 <div>
//                   <div className="d-sm-none">
//                     <div className="d-flex flex-column lh-sm">
//                       <span className="navbar-brand fw-bold py-0">
//                         {getNavbarTab(currentRouteID)}
//                       </span>
//                       <small className="text-muted">Plaza - Texas A&M</small>
//                     </div>
//                   </div>
//                   <span className=" d-none d-sm-block navbar-brand fw-bold py-0">
//                     Plaza <span className="text-muted ms-2">Texas A&M</span>
//                   </span>
//                 </div>
//               )}

//               {isLanding || !isAuthenticated ? (
//                 <span />
//               ) : (
//                 <button
//                   className="navbar-toggler"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#navbarNav"
//                   aria-controls="navbarNav"
//                   aria-expanded="false"
//                   aria-label="Toggle navigation"
//                 >
//                   <span className="navbar-toggler-icon"></span>
//                 </button>
//               )}

//               {isAuthenticated ? (
//                 <div
//                   className={"collapse navbar-collapse justify-content-between"}
//                   id="navbarNav"
//                 >
//                   <ul className="navbar-nav">
//                     {NAV_ITEMS.map((e) => {
//                       const vw = Math.max(
//                         document.documentElement.clientWidth || 0,
//                         window.innerWidth || 0
//                       );
//                       const isActive = currentRouteID === e.id;

//                       const isSmallOrLarger = vw >= 576;

//                       const navLinkClass1 = isActive
//                         ? "border bg-light text-white rounded"
//                         : "border border-white";
//                       return (
//                         <li
//                           className={`nav-item ${
//                             isSmallOrLarger ? navLinkClass1 : ""
//                           }`}
//                           key={e.id}
//                         >
//                           <Link
//                             className={`nav-link ${isActive ? "active" : ""}`}
//                             aria-current="page"
//                             to={e.linkTo}
//                           >
//                             {e.name}
//                           </Link>
//                         </li>
//                       );
//                     })}
//                   </ul>
//                 </div>
//               ) : (
//                 <button
//                   className={`btn ${
//                     isLanding ? "btn-light" : "btn-primary"
//                   } ms-auto`}
//                   onClick={signInWithGoogleHandler}
//                 >
//                   <span>Sign In with Google</span>
//                 </button>
//               )}
//             </div>
//           </nav>
//         );
//       }}
//     </UserContext.Consumer>
//   );
// };

// export default Navbar;

// const signIn = async () => {
//   const auth = getAuth(app);
//   await signInWithEmailAndPassword(
//     auth,
//     "zacharyeilers03+user6@gmail.com",
//     "password"
//   );
// };

/* <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/home"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/events">
                    Events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/messages">
                    Messages
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/groups">
                    Groups
                  </Link>
                </li> */
