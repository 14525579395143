import * as React from "react";

/*
TODO LIST end DEC 7 2022
add button to expand the number of books shown in the grid in textbooks market
add firebase storage and optional image input
add optional description input
add form validation for posting textbook
add user market items card in home screen
add security rules
add terms of service
deploy
*/

import { app, db } from "./firebase";

import { getAuth, User, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  addDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  query,
  orderBy,
  limit,
  where,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import {
  UserDoc,
  UserDocSchoolTermData,
  SchoolTermCacheDocData,
  SchoolTermCacheTwoDocData,
  CourseDocData,
  CourseDoc,
  SchoolTermDocData,
  TermCourseSectionTextbooksCache,
  CourseSectionTextbookIDsMap,
  TermTextbooksCache,
  TextbookData,
  MarketItem,
  TextbookMarketItem,
  TextbookMarketItemCreationData,
  MarketItemCreationData,
  marketItemFromLocalStorage,
} from "./models";
import { UserContext } from "./UserState";

export enum LoadingState {
  beforeLoad,
  loading,
  loaded,
}
export interface LoadingTermsState {
  [key: string]: LoadingState;
}

export interface DynamicObj {
  [k: string]: any;
}

export interface AppData {
  termsCache: TermsCache;
  termsCacheTwo: TermsCacheTwo;
  setTermsCache: (schoolID: string, terms: SchoolTermCacheDocData[]) => void;
  setTermsCacheTwo: (
    schoolID: string,
    terms: SchoolTermCacheTwoDocData[]
  ) => void;
  loadTermsCache: (schoolID: string, termID: string) => Promise<void>;
  loadTermsCacheTwo: (schoolID: string, termID: string) => Promise<void>;
  termsCacheLoaded: (
    schoolTermsCache: SchoolTermCacheDocData[],
    termID: string
  ) => boolean;
  termsCacheTwoLoaded: (
    schoolTermsCache: SchoolTermCacheTwoDocData[],
    termID: string
  ) => boolean;
  termTextbooksCache: AppStateTermTextbooksCache;
  termCourseSectionTextbooksCache: AppStateTermCourseSectionTextbooksCache;
  setTermTextbooksCache: (
    schoolID: string,
    newTerms: TermTextbooksCache[]
  ) => void;
  setTermCourseSectionTextbooksCache: (
    schoolID: string,
    newTerms: TermCourseSectionTextbooksCache[]
  ) => void;
  loadTermTextbooksCache: (schoolID: string, termID: string) => Promise<void>;
  loadTermCourseSectionTextbooksCache: (
    schoolID: string,
    termID: string
  ) => Promise<void>;
  termTextbooksCacheLoaded: (
    schoolTextbooksCache: TermTextbooksCache[],
    termID: string
  ) => boolean;
  termCourseSectionTextbooksCacheLoaded: (
    schoolTextbooksCache: TermCourseSectionTextbooksCache[],
    termID: string
  ) => boolean;
  getTermTextbooksCacheForTerm: (
    schoolTextbooksCache: TermTextbooksCache[],
    termID: string
  ) => TermTextbooksCache | null;
  getTermCourseSectionTextbooksCacheForTerm: (
    schoolTextbooksCache: TermCourseSectionTextbooksCache[],
    termID: string
  ) => TermCourseSectionTextbooksCache | null;
  marketItems: AppStateMarketItems;
  loadAllMarketItems: (schoolID: string) => Promise<void>;
  loadTextbookMarketItems: (
    schoolID: string,
    textbookID: string,
    quantity: number,
    dateWindowRecentEnd: Date
  ) => Promise<MarketItem[]>;
  loadUserMarketItems: (schoolID: string, userID: string) => Promise<void>;
  setMarketItems: (
    schoolID: string,
    newItems: MarketItem[],
    removedMarketItemsIDs: string[]
  ) => void;
  postMarketItem: (
    schoolID: string,
    sellerUID: string,
    newItem: TextbookMarketItemCreationData
  ) => Promise<{
    userMarketItems: MarketItem[];
    newMarketItem: MarketItem;
    success: boolean;
    message: string;
  }>;
  deleteMarketItem: (
    schoolID: string,
    sellerUID: string,
    itemID: string
  ) => Promise<{
    userMarketItems: MarketItem[];
    success: boolean;
    message: string;
  }>;
  updateMarketItem: (
    schoolID: string,
    sellerUID: string,
    itemUpdated: TextbookMarketItem
  ) => Promise<{
    userMarketItems: MarketItem[];
    updatedMarketItem: MarketItem;
    success: boolean;
    message: string;
  }>;
}

interface AppStateTermTextbooksCache {
  [schoolID: string]: TermTextbooksCache[];
}

interface AppStateTermCourseSectionTextbooksCache {
  [schoolID: string]: TermCourseSectionTextbooksCache[];
}

interface TermsCache {
  [schoolID: string]: SchoolTermCacheDocData[];
}

interface TermsCacheTwo {
  [schoolID: string]: SchoolTermCacheTwoDocData[];
}

interface AppStateMarketItems {
  [schoolID: string]: MarketItem[];
}

const getTermArrayUniquesOnly = <T extends { termID: string }>(
  currState: T[],
  newTerms: T[]
): T[] => {
  const newTermArray = [...newTerms, ...currState];
  const newTermArrayIDs = newTermArray.map((term) => term.termID);
  const newTermArrayIDsUnique = Array.from(new Set(newTermArrayIDs));
  const newTermArrayUnique = newTermArrayIDsUnique.map((id) => {
    return newTermArray.filter((t) => t.termID === id)[0];
  });

  return newTermArrayUnique;
};

type TermsCacheName = "termsCache" | "termsCacheTwo";
type TermsCacheVariant = TermsCache | TermsCacheTwo;
type TermsCacheDataVariant =
  | SchoolTermCacheDocData[]
  | SchoolTermCacheTwoDocData[];

const getTermsCacheLocalStorageVariant = <T extends TermsCacheVariant>(
  cacheName: TermsCacheName
) => {
  const stored = localStorage.getItem(cacheName) ?? "{}";
  const parsed = JSON.parse(stored) as T;
  const empty = {} as T;
  const obj = stored === null ? empty : parsed;
  return obj;
};

const saveTermsCacheLocalStorageVariant = <T extends TermsCacheVariant>(
  cacheName: TermsCacheName,
  schoolID: string,
  newTermArray: TermsCacheDataVariant
) => {
  const stored = localStorage.getItem(cacheName) ?? "{}";
  const parsed = JSON.parse(stored) as T;
  const empty = {} as T;
  const obj = stored === null ? parsed : empty;

  localStorage.setItem(
    cacheName,
    JSON.stringify({ ...obj, [schoolID]: newTermArray })
  );
};

const getTermsCacheStateSetterVariant = <T extends TermsCacheDataVariant>(
  cacheName: TermsCacheName,
  setAppData: React.Dispatch<React.SetStateAction<AppData>>
) => {
  return (schoolID: string, newState: T) => {
    setAppData((st: AppData) => {
      const currState = st[cacheName][schoolID] ?? [];
      const newTermArray = getTermArrayUniquesOnly(currState, newState);

      saveTermsCacheLocalStorageVariant(cacheName, schoolID, newTermArray);

      return {
        ...st,
        [cacheName]: {
          ...st[cacheName],
          [schoolID]: newTermArray,
        },
      } as AppData;
    });
  };
};

type TermTextbooksCacheName =
  | "termTextbooksCache"
  | "termCourseSectionTextbooksCache";
type TermTextbooksCacheVariant =
  | AppStateTermTextbooksCache
  | AppStateTermCourseSectionTextbooksCache;
type TermTextbooksCacheDataVariant =
  | TermTextbooksCache
  | TermCourseSectionTextbooksCache;

const getTermTextbooksCache = <
  T extends TermTextbooksCacheVariant,
  D extends TermTextbooksCacheDataVariant
>(
  cacheName: TermTextbooksCacheName
) => {
  const stored = localStorage.getItem(cacheName) ?? "{}";
  const parsed = JSON.parse(stored) as T;
  const empty = {} as T;
  const obj = stored === null ? empty : parsed;

  let uniqueObj: { [schoolID: string]: D[] } = {};

  Object.keys(obj).forEach((schoolID) => {
    uniqueObj[schoolID] = getTermArrayUniquesOnly<D>(obj[schoolID] as D[], []);
  });

  return uniqueObj;
};

const saveTermTextbooksCache = <T extends TermTextbooksCacheVariant>(
  cacheName: TermTextbooksCacheName,
  schoolID: string,
  newTermArray: TermTextbooksCacheDataVariant[]
) => {
  const stored = localStorage.getItem(cacheName) ?? "{}";
  const parsed = JSON.parse(stored) as T;
  const empty = {} as T;
  const obj = stored === null ? empty : parsed;

  localStorage.setItem(
    cacheName,
    JSON.stringify({ ...obj, [schoolID]: newTermArray })
  );
};

const getTermTextbooksCacheStateSetterVariant = <
  D extends TermTextbooksCacheDataVariant
>(
  cacheName: TermTextbooksCacheName,
  setAppData: React.Dispatch<React.SetStateAction<AppData>>
) => {
  return (schoolID: string, newState: D[]) => {
    setAppData((st: AppData) => {
      const currState: D[] = (st[cacheName][schoolID] as D[]) ?? ([] as D[]);
      const newTermArray = getTermArrayUniquesOnly<D>(
        currState,
        newState as D[]
      );

      saveTermTextbooksCache(cacheName, schoolID, newTermArray);

      return {
        ...st,
        [cacheName]: {
          ...st[cacheName],
          [schoolID]: newTermArray,
        },
      } as AppData;
    });
  };
};

const getLocalStorageMarketItems = () => {
  const stored = localStorage.getItem("marketItems") ?? "{}";
  const parsed = JSON.parse(stored) as AppStateMarketItems;
  const empty = {} as AppStateMarketItems;
  const obj = stored === null ? empty : parsed;

  let datesFixed: AppStateMarketItems = {};
  Object.keys(obj).map((s) => {
    console.log(s);
    datesFixed[s] = obj[s].map((item) => {
      return marketItemFromLocalStorage(item);
    });
  });

  return datesFixed;
};

const saveLocalStorageMarketItems = (
  schoolID: string,
  newMarketItems: MarketItem[]
) => {
  const stored = localStorage.getItem("marketItems") ?? "{}";
  const parsed = JSON.parse(stored) as AppStateMarketItems;
  const empty = {} as AppStateMarketItems;
  const obj = stored === null ? empty : parsed;

  localStorage.setItem(
    "marketItems",
    JSON.stringify({ ...obj, [schoolID]: newMarketItems })
  );
};

const getMarketItemsStateSetter = (
  setAppData: React.Dispatch<React.SetStateAction<AppData>>
) => {
  return (
    schoolID: string,
    newMarketItems: MarketItem[],
    removedMarketItemsIDs: string[]
  ) => {
    setAppData((st: AppData) => {
      const currState: MarketItem[] =
        (st.marketItems[schoolID] as MarketItem[]) ?? ([] as MarketItem[]);

      const fullItems = [...newMarketItems, ...currState].filter((item) => {
        return !removedMarketItemsIDs.includes(item.marketItemID);
      });

      const fullItemsIDs = fullItems.map((item) => item.marketItemID);
      const fullItemsIDsUnique = Array.from(new Set(fullItemsIDs));
      const fullItemsUnique = fullItemsIDsUnique.map((id) => {
        return fullItems.filter((item) => item.marketItemID === id)[0];
      });

      // saveLocalStorageMarketItems(schoolID, fullItemsUnique);

      return {
        ...st,
        marketItems: {
          ...st.marketItems,
          [schoolID]: fullItemsUnique,
        },
      } as AppData;
    });
  };
};

// setAppData((st) => {
//   const currState = st.termCourseSectionTextbooksCache[schoolID] ?? [];

//   const newTermArray = getTermArrayUniquesOnly<TermCourseSectionTextbooksCache>(
//     currState,
//     [cacheDocData]
//   );

//   saveTermCourseSectionTextbooksCache(schoolID, newTermArray);

//   console.log("state updated after load");
//   return {
//     ...st,
//     termCourseSectionTextbooksCache: {
//       ...st.termCourseSectionTextbooksCache,
//       [schoolID]: newTermArray,
//     },
//   };
// });

const DEFAULT_APP_DATA: AppData = {
  termsCache: getTermsCacheLocalStorageVariant<TermsCache>("termsCache"),
  termsCacheTwo:
    getTermsCacheLocalStorageVariant<TermsCacheTwo>("termsCacheTwo"),
  setTermsCache: (schoolID, terms) => {},
  setTermsCacheTwo: (schoolID, terms) => {},
  loadTermsCache: async (schoolID, termID) => {},
  loadTermsCacheTwo: async (schoolID, termID) => {},
  termsCacheLoaded: (schoolTermsCache, termID: string) => false,
  termsCacheTwoLoaded: (schoolTermsCache, termID: string) => false,
  termTextbooksCache: getTermTextbooksCache<
    AppStateTermTextbooksCache,
    TermTextbooksCache
  >("termTextbooksCache"),
  termCourseSectionTextbooksCache: getTermTextbooksCache<
    AppStateTermCourseSectionTextbooksCache,
    TermCourseSectionTextbooksCache
  >("termCourseSectionTextbooksCache"),
  setTermTextbooksCache: (schoolID, newTermCache) => {},
  setTermCourseSectionTextbooksCache: (schoolID, newTermCache) => {},
  loadTermTextbooksCache: async (schoolID, termID) => {},
  loadTermCourseSectionTextbooksCache: async (schoolID, termID) => {
    console.log("running default cst cache");
  },
  termTextbooksCacheLoaded: (schoolTextbooksCache, termID) => false,
  termCourseSectionTextbooksCacheLoaded: (schoolTextbooksCache, termID) =>
    false,
  getTermTextbooksCacheForTerm: (schoolTextbooksCache, termID) => null,
  getTermCourseSectionTextbooksCacheForTerm: (schoolTextbooksCache, termID) =>
    null,
  marketItems: {} as AppStateMarketItems,
  loadAllMarketItems: async (schoolID) => {},
  loadTextbookMarketItems: async (
    schoolID,
    textbookID,
    quantity,
    dateWindowRecentEnd
  ) => [] as MarketItem[],
  loadUserMarketItems: async (schoolID, userID) => {},
  setMarketItems: (schoolID, newItems) => {},
  postMarketItem: async (schoolID, sellerUID, newItem) => {
    return {
      userMarketItems: [],
      newMarketItem: {} as MarketItem,
      success: false,
      message: "",
    };
  },
  deleteMarketItem: async (schoolID, sellerUID, itemID) => {
    return {
      userMarketItems: [],
      success: false,
      message: "",
    };
  },
  updateMarketItem: async (schoolID, sellerUID, itemUpdated) => {
    return {
      userMarketItems: [],
      updatedMarketItem: {} as MarketItem,
      success: false,
      message: "",
    };
  },
};

export const AppContext = React.createContext<AppData>(DEFAULT_APP_DATA);

//TODO refactor load, loaded, and getForTerm methods for
//TermTextbooks and TermCourseSectionTextbooks to use a generic function
const AppState: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [appData, setAppData] = React.useState<AppData>(DEFAULT_APP_DATA);

  // const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    console.log("Set Up AppState UseEffect");
    // console.log("User Context Signed In", userContext.signedIn);

    setAppData({
      termsCache: DEFAULT_APP_DATA.termsCache,
      termsCacheTwo: DEFAULT_APP_DATA.termsCacheTwo,
      loadTermsCache: async function (schoolID, termID) {
        const termsCacheDocRef = doc(
          db,
          `/schools/${schoolID}/termsCache/${termID}`
        );

        const termsCacheDoc = await getDoc(termsCacheDocRef);

        const termsCacheDocData =
          termsCacheDoc.data() as SchoolTermCacheDocData;

        getTermsCacheStateSetterVariant("termsCache", setAppData)(schoolID, [
          termsCacheDocData,
        ]);
      },
      loadTermsCacheTwo: async function (schoolID, termID) {
        const termsCacheTwoDocRef = doc(
          db,
          `/schools/${schoolID}/termsCacheTwo/${termID}`
        );

        console.log("loading terms cache two");

        const termsCacheTwoDoc = await getDoc(termsCacheTwoDocRef);

        const termsCacheTwoDocData =
          termsCacheTwoDoc.data() as SchoolTermCacheTwoDocData;

        getTermsCacheStateSetterVariant("termsCacheTwo", setAppData)(schoolID, [
          termsCacheTwoDocData,
        ]);

        console.log("loaded terms cache two", appData.termsCacheTwo);
      },
      termsCacheLoaded: function (schoolTermsCache, termID: string) {
        if (!schoolTermsCache) return false;

        const matchedLoadedTerms = schoolTermsCache.filter(
          (term) => term.termID === termID
        );

        if (matchedLoadedTerms.length === 0) {
          return false;
        } else if (matchedLoadedTerms.length === 1) {
          return true;
        } else {
          throw "AppState, termsCacheLoaded: We found two terms with same id";
        }
      },
      termsCacheTwoLoaded: function (schoolTermsCacheTwo, termID: string) {
        if (!schoolTermsCacheTwo) return false;

        const matchedLoadedTerms = schoolTermsCacheTwo.filter(
          (term) => term.termID === termID
        );

        if (matchedLoadedTerms.length === 0) {
          return false;
        } else if (matchedLoadedTerms.length === 1) {
          return true;
        } else {
          throw "AppState, termsCacheTwoLoaded: We found two terms with same id";
        }
      },
      setTermsCache: getTermsCacheStateSetterVariant("termsCache", setAppData),
      setTermsCacheTwo: getTermsCacheStateSetterVariant(
        "termsCacheTwo",
        setAppData
      ),

      termTextbooksCache: DEFAULT_APP_DATA.termTextbooksCache,
      termCourseSectionTextbooksCache:
        DEFAULT_APP_DATA.termCourseSectionTextbooksCache,

      setTermTextbooksCache: getTermTextbooksCacheStateSetterVariant(
        "termTextbooksCache",
        setAppData
      ),
      setTermCourseSectionTextbooksCache:
        getTermTextbooksCacheStateSetterVariant(
          "termCourseSectionTextbooksCache",
          setAppData
        ),
      loadTermTextbooksCache: async (schoolID, termID) => {
        const termsCacheDocRef = doc(
          db,
          `/schools/${schoolID}/termTextbooksCache/${termID}`
        );

        const cacheDoc = await getDoc(termsCacheDocRef);
        const cacheDocData = cacheDoc.data() as TermTextbooksCache;

        getTermTextbooksCacheStateSetterVariant(
          "termTextbooksCache",
          setAppData
        )(schoolID, [cacheDocData]);
      },
      loadTermCourseSectionTextbooksCache: async (schoolID, termID) => {
        console.log("loading cst cache");
        const termsCacheTwoDocRef = doc(
          db,
          `/schools/${schoolID}/termCourseSectionTextbooksCache/${termID}`
        );

        const cacheDoc = await getDoc(termsCacheTwoDocRef);
        const cacheDocData = cacheDoc.data() as TermCourseSectionTextbooksCache;

        getTermTextbooksCacheStateSetterVariant(
          "termCourseSectionTextbooksCache",
          setAppData
        )(schoolID, [cacheDocData]);
      },
      termTextbooksCacheLoaded: (schoolTextbooksCache, termID) => {
        if (!schoolTextbooksCache) return false;

        const matchedLoadedTerms = schoolTextbooksCache.filter(
          (term) => term.termID === termID
        );

        if (matchedLoadedTerms.length === 0) {
          return false;
        } else if (matchedLoadedTerms.length === 1) {
          return true;
        } else {
          throw "AppState, termTextbooksCacheLoaded: We found two terms with same id";
        }
      },
      termCourseSectionTextbooksCacheLoaded: (schoolTextbooksCache, termID) => {
        if (!schoolTextbooksCache) return false;

        const matchedLoadedTerms = schoolTextbooksCache.filter(
          (term) => term.termID === termID
        );

        if (matchedLoadedTerms.length === 0) {
          return false;
        } else if (matchedLoadedTerms.length === 1) {
          return true;
        } else {
          throw "AppState, termCourseSectionTextbooksCacheLoaded: We found two terms with same id";
        }
      },
      getTermTextbooksCacheForTerm: (schoolTextbooksCache, termID) => {
        const matchedTerms = schoolTextbooksCache.filter(
          (t) => t.termID === termID
        );
        if (matchedTerms.length > 1) {
          throw "AppState, getTermCourseSectionTextbooksCacheForTerm: We found two terms with same id";
        } else if (matchedTerms.length === 0) {
          return null;
        } else {
          return matchedTerms[0];
        }
      },
      getTermCourseSectionTextbooksCacheForTerm: (
        schoolTextbooksCache,
        termID
      ) => {
        const matchedTerms = schoolTextbooksCache.filter(
          (t) => t.termID === termID
        );
        if (matchedTerms.length > 1) {
          throw "AppState, getTermCourseSectionTextbooksCacheForTerm: We found two terms with same id";
        } else if (matchedTerms.length === 0) {
          return null;
        } else {
          return matchedTerms[0];
        }
      },
      marketItems: DEFAULT_APP_DATA.marketItems,
      loadAllMarketItems: async (schoolID) => {
        console.log("being called");
        const marketItemsColRef = collection(
          db,
          `/schools/${schoolID}/marketItems`
        );

        const marketDocs = await getDocs(marketItemsColRef);
        const marketItems: MarketItem[] = marketDocs.docs.map((doc) => {
          const docData = doc.data() as MarketItem;
          const updatedDates =
            (docData.updatedDates as any as Timestamp[]) ?? [];
          return {
            ...docData,
            postedDate: (docData.postedDate as any as Timestamp).toDate(),
            updatedDates: updatedDates.map((t) => t.toDate()),
          };
        });

        getMarketItemsStateSetter(setAppData)(schoolID, marketItems, []);
      },
      loadTextbookMarketItems: async (
        schoolID,
        textbookID,
        quantity,
        dateWindowRecentEnd
      ) => {
        const marketItemsColRef = collection(
          db,
          `/schools/${schoolID}/marketItems`
        );
        const docsQuery = query(
          marketItemsColRef,
          where("postedDate", "<", Timestamp.fromDate(dateWindowRecentEnd)),
          where("textbookID", "==", textbookID),
          orderBy("postedDate", "desc"),
          limit(quantity)
        );
        const marketDocs = await getDocs(docsQuery);
        const marketItems: MarketItem[] = marketDocs.docs.map((doc) => {
          const docData = doc.data() as MarketItem;
          const updatedDates =
            (docData.updatedDates as any as Timestamp[]) ?? [];
          return {
            ...docData,
            postedDate: (docData.postedDate as any as Timestamp).toDate(),
            updatedDates: updatedDates.map((t) => t.toDate()),
          };
        });
        // console.log("new market items", marketItems);

        getMarketItemsStateSetter(setAppData)(schoolID, marketItems, []);

        return marketItems;
      },
      loadUserMarketItems: async (schoolID, userID) => {
        const marketItemsColRef = collection(
          db,
          `/schools/${schoolID}/marketItems`
        );
        const docsQuery = query(
          marketItemsColRef,
          where("sellerUID", "==", userID)
        );
        const marketDocs = await getDocs(docsQuery);
        const marketItems: MarketItem[] = marketDocs.docs.map((doc) => {
          const docData = doc.data() as MarketItem;

          const updatedDates =
            (docData.updatedDates as any as Timestamp[]) ?? [];
          return {
            ...docData,
            postedDate: (docData.postedDate as any as Timestamp).toDate(),
            updatedDates: updatedDates.map((t) => t.toDate()),
          };
        });

        getMarketItemsStateSetter(setAppData)(schoolID, marketItems, []);
      },

      setMarketItems: getMarketItemsStateSetter(setAppData),
      postMarketItem: async (schoolID, userID, newItem) => {
        const userDocRef = doc(db, `/users/${userID}`);
        const marketItemsColRef = collection(
          db,
          `/schools/${schoolID}/marketItems`
        );

        try {
          const { createdItem, marketItemsArray } = await runTransaction(
            db,
            async (transaction) => {
              //construct the new market item
              const nowDate = new Date();
              const newDocRef = doc(marketItemsColRef);
              const addedItem: MarketItem = {
                marketItemID: newDocRef.id,
                ...(newItem as MarketItemCreationData),
                postedDate: nowDate,
              };

              //get the user's doc
              const fetchedUserDoc = await transaction.get(userDocRef);
              if (!fetchedUserDoc.exists()) {
                throw "User Doc does not exist!";
              }
              const fetchedUserDocData = fetchedUserDoc.data() as UserDoc;

              //set up the user's new marketItems field
              const userMarketItemsArray = [
                ...(fetchedUserDocData?.marketItems ?? []),
                addedItem,
              ];

              //add the doc to the school's market
              await transaction.set(newDocRef, {
                ...addedItem,
                clientPostedDate: Timestamp.fromDate(nowDate),
                postedDate: serverTimestamp(),
              });

              //update the user's market items array
              await transaction.update(userDocRef, {
                marketItems: userMarketItemsArray,
              });

              return {
                createdItem: addedItem,
                marketItemsArray: userMarketItemsArray,
              };
            }
          );
          getMarketItemsStateSetter(setAppData)(schoolID, [createdItem], []);

          return {
            userMarketItems: marketItemsArray,
            newMarketItem: createdItem,
            message: "Item Successfully Posted!",
            success: true,
          };
        } catch (err) {
          console.log("Transaction failed: ", err);
          return {
            userMarketItems: [] as MarketItem[],
            newMarketItem: {} as MarketItem,
            message: "Failed to Post Item. Try again later.",
            success: false,
          };
        }
      },
      deleteMarketItem: async (schoolID, userID, itemID) => {
        const userDocRef = doc(db, `/users/${userID}`);

        const marketItemDocRef = doc(
          db,
          `/schools/${schoolID}/marketItems/${itemID}`
        );
        try {
          const { marketItemsArray } = await runTransaction(
            db,
            async (transaction) => {
              //remove the item from the user's doc
              const fetchedUserDoc = await transaction.get(userDocRef);

              if (!fetchedUserDoc.exists()) {
                throw "User Doc does not exist!";
              }
              const fetchedUserDocData = fetchedUserDoc.data() as UserDoc;
              const userMarketItemsArray =
                fetchedUserDocData.marketItems.filter(
                  (item) => item.marketItemID !== itemID
                );

              await transaction.update(userDocRef, {
                marketItems: userMarketItemsArray,
              });

              //delete the item from the school's market
              await transaction.delete(marketItemDocRef);

              return { marketItemsArray: userMarketItemsArray };
            }
          );
          getMarketItemsStateSetter(setAppData)(schoolID, [], [itemID]);

          return {
            userMarketItems: marketItemsArray,
            message: "Item Successfully Deleted.",
            success: true,
          };
        } catch (err) {
          console.log("Transaction failed: ", err);
          return {
            userMarketItems: [] as MarketItem[],
            message: "Failed to Delete Item. Try again later.",
            success: false,
          };
        }
      },
      updateMarketItem: async (schoolID, userID, updatedItem: MarketItem) => {
        const userDocRef = doc(db, `/users/${userID}`);

        const marketItemDocRef = doc(
          db,
          `/schools/${schoolID}/marketItems/${updatedItem.marketItemID}`
        );

        try {
          const { changedItem, marketItemsArray } = await runTransaction(
            db,
            async (transaction) => {
              //get the user's doc
              const fetchedUserDoc = await transaction.get(userDocRef);
              if (!fetchedUserDoc.exists()) {
                throw "User Doc does not exist!";
              }

              const currItemDoc = await transaction.get(marketItemDocRef);
              const currItemDocData = currItemDoc.data() as MarketItem;

              //create the new market item doc
              const nowDate = new Date();
              const newItemDoc: MarketItem = {
                ...updatedItem,
                updatedDates: [
                  ...(currItemDocData.updatedDates ?? []),
                  nowDate,
                ],
              };

              //update the marketItem doc in the school's market
              await transaction.update(marketItemDocRef, newItemDoc as any);

              //set up the user's new marketItems array
              const fetchedUserDocData = fetchedUserDoc.data() as UserDoc;
              const currUserItemsArr = fetchedUserDocData?.marketItems ?? [];
              const userItemsArrItemRemoved = currUserItemsArr.filter(
                (i) => i.marketItemID !== updatedItem.marketItemID
              );
              const newUserItemsArr = [...userItemsArrItemRemoved, newItemDoc];

              //update the user's doc with the new marketItems array
              await transaction.update(userDocRef, {
                marketItems: newUserItemsArr,
              });

              return {
                changedItem: newItemDoc as MarketItem,
                marketItemsArray: newUserItemsArr,
              };
            }
          );
          getMarketItemsStateSetter(setAppData)(schoolID, [changedItem], []);

          return {
            userMarketItems: marketItemsArray,
            updatedMarketItem: changedItem,
            message: "Item Successfully Updated!",
            success: true,
          };
        } catch (err) {
          console.log("Transaction failed: ", err);
          return {
            userMarketItems: [] as MarketItem[],
            updatedMarketItem: {} as MarketItem,
            message: "Failed to Update Item. Try again later.",
            success: false,
          };
        }
      },
    });
  }, []);

  return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
};

export default AppState;

// setAppData((st: AppData) => {
//   const currState = st.termsCacheTwo[schoolID] ?? [];
//   const newTermArray = getTermArrayUniquesOnly(currState, [
//     termsCacheTwoDocData,
//   ]);

//   saveTermsCacheLocalStorageVariant(
//     "termsCacheTwo",
//     schoolID,
//     newTermArray
//   );

//   return {
//     ...st,
//     termsCacheTwo: {
//       ...st.termsCacheTwo,
//       [schoolID]: newTermArray,
//     },
//   } as AppData;
// });
