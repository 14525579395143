import * as React from "react";

import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  Link,
  useLocation,
} from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

import AppState from "./AppState";

import RootPage from "./routes/root/RootPage";
import HomePage, { homePageLoader } from "./routes/home/HomePage";

import UserMessagesPage, {
  userMessagesPageLoader,
} from "./routes/messages/UserMessagesPage";

import ProfilePage, { profilePageLoader } from "./routes/user/ProfilePage";
import UserPage, { userPageLoader } from "./routes/user/UserPage";
import UserMessagesMessagePage, {
  userMessageMessagePageLoader,
} from "./routes/messages/UserMessagesMessagePage";

import MarketPage, { marketPageLoader } from "./routes/market/MarketPage";
import MarketItemAddPage, {
  marketItemAddPageLoader,
} from "./routes/market/MarketItemAddPage";

import MarketItemPage, {
  marketItemPageLoader,
} from "./routes/market/MarketItemPage";
import PeoplePage, { peoplePageLoader } from "./routes/people/PeoplePage";
import UserState from "./UserState";
import DataRequestPage from "./routes/root/DataRequestPage";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="container">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Link to="/">
        <button className="btn btn-danger">Go to Root Page</button>
      </Link>
    </div>
  );
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage />,
    },
    {
      path: "/home",
      element: <HomePage />,
      errorElement: <ErrorPage />,
      loader: homePageLoader,
    },
    {
      path: "/market",
      element: <MarketPage />,
      errorElement: <ErrorPage />,
      loader: marketPageLoader,
    },
    {
      path: "/market/post",
      element: <MarketItemAddPage />,
      errorElement: <ErrorPage />,
      loader: marketItemAddPageLoader,
    },
    {
      path: "/market/:marketItemID",
      element: <MarketItemPage />,
      errorElement: <ErrorPage />,
      loader: marketItemPageLoader,
    },
    {
      path: "/market/:marketItemID/edit",
      element: <MarketItemAddPage />,
      errorElement: <ErrorPage />,
      loader: marketItemAddPageLoader,
    },
    // {
    //   path: "/shop/:userHandle",
    //   element: <UserMessagesPage />,
    //   loader: userMessagesPageLoader,
    //   errorElement: <ErrorPage />,
    // },
    // {
    //   path: "/messages",
    //   element: <UserMessagesPage />,
    //   loader: userMessagesPageLoader,
    //   errorElement: <ErrorPage />,
    // },
    // {
    //   path: "/messages/:messageRoomID",
    //   element: <UserMessagesMessagePage />,
    //   errorElement: <ErrorPage />,
    //   loader: userMessageMessagePageLoader,
    // },
    // {
    //   path: "/people",
    //   element: <PeoplePage />,
    //   errorElement: <ErrorPage />,
    //   loader: peoplePageLoader,
    // },
    // {
    //   path: "/user/:userUID",
    //   element: <UserPage />,
    //   errorElement: <ErrorPage />,
    //   loader: userPageLoader,
    // },
    {
      path: "/profile",
      element: <ProfilePage />,
      errorElement: <ErrorPage />,
      loader: profilePageLoader,
    },
    {
      path: "/data-request",
      element: <DataRequestPage />,
      errorElement: <ErrorPage />,
      loader: profilePageLoader,
    },
  ]);

  return (
    <AppState>
      <UserState>
        <RouterProvider router={router} />
      </UserState>
    </AppState>
  );
}

export default App;

// import UserEventsPage from "./routes/events/UserEventsPage";
// import AllEventsPage from "./routes/events/AllEventsPage";
// import EventPage from "./routes/events/EventPage";

// import OrgPage from "./routes/orgs/OrgPage";
// import OrgEventsPage from "./routes/orgs/events/OrgEventsPage";
// import OrgDocumentsPage from "./routes/orgs/documents/OrgDocumentsPage";
// import OrgDocumentsDocumentPage from "./routes/orgs/documents/OrgDocumentsDocumentPage";
// import OrgAnnouncementsPage from "./routes/orgs/announcements/OrgAnnouncementsPage";
// import OrgAnnouncementsAnnouncementPage from "./routes/orgs/announcements/OrgAnnouncementsAnnouncementPage";
// import OrgGroupsPage from "./routes/orgs/groups/OrgGroupsPage";
// import OrgGroupsGroupPage from "./routes/orgs/groups/OrgGroupsGroupPage";
// import OrgsSettingsPage from "./routes/orgs/settings/OrgsSettingsPage";

// {
//   path: "/events",
//   element: <UserEventsPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/events/all",
//   element: <AllEventsPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/events/:eventID",
//   element: <EventPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID",
//   element: <OrgPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/events",
//   element: <OrgEventsPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/events/:eventID",
//   element: <EventPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/documents/",
//   element: <OrgDocumentsPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/documents/:documentID",
//   element: <OrgDocumentsDocumentPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/announcements",
//   element: <OrgAnnouncementsPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/announcements/:announcementID",
//   element: <OrgAnnouncementsAnnouncementPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/groups",
//   element: <OrgGroupsPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/groups/:groupID",
//   element: <OrgGroupsGroupPage />,
//   errorElement: <ErrorPage />,
// },
// {
//   path: "/orgs/:orgID/settings/:settingID",
//   element: <OrgsSettingsPage />,
//   errorElement: <ErrorPage />,
// },
