import { FieldValue, Timestamp } from "firebase/firestore";

export enum MessageTypeEnum {
  text,
  image,
  video,
  document,
  poll,
}

export enum ReasonTypeEnum {
  excused,
  unexcused,
}

export interface Organization {
  ID: string;
  orgName: string;
  orgInstitutionID: string;
  orgOwner: string;
  orgManagers: string[];
  orgMembers: string[];
  memberPoints: Map<string, Array<MemberPointsEntryMap>>;
}

export interface MemberPointsEntryMap {
  eventID: string;
  pointsAwarded: number;
}

export interface OrganizationGroup {
  ID: string;
  name: string;
  memberUIDs: Array<string>;
  documents: Array<LinkedDocument>;
}

export interface ChatChannel {
  ID: string;
  name: string;
}

export interface OrganizationEvent {
  ID: string;
  title: string;
  description: string;
  startDate: Timestamp;
  endDate: Timestamp;
  locationData: {
    locationName: string;
    googleMapsLink: string;
    appleMapsLink: string;
  };
  attachedDocuments: LinkedDocument[];
  hasRequiredAttendance: boolean;
  pointsForAttending: number;
  confirmedGoing: AttendanceGoingEntry[];
  waitingList: AttendanceWaitingListEntry[];
  confirmedNotGoing: AttendanceNotGoingEntry[];
}

export interface AttendanceEntry {
  UID: string;
  entryDate: Timestamp;
}

export interface AttendanceGoingEntry extends AttendanceEntry {}
export interface AttendanceWaitingListEntry extends AttendanceEntry {}
export interface AttendanceNotGoingEntry extends AttendanceEntry {
  reason: {
    type: ReasonTypeEnum;
    details: string;
  };
}

export interface Announcement {
  ID: string;
  title: string;
  contents: string;
  dateSent: Timestamp;
  viewed: Array<ViewedEntry>;
}

export interface ViewedEntry {
  UID: string;
  dateViewed: Timestamp;
}

export interface LinkedDocument {
  googleDriveLink: string;
}

export interface Message {
  messageType: MessageTypeEnum;
}

export interface TextMessage {}

export interface ImageMessage {}

export interface VideoMessage {}

export interface DocumentMessage {}

export interface PollMessage {}

export interface OrganizationChat {
  ID: string;
  name: string;
  memberUIDs: string[];
}

// root/schools/:school-id/terms/:term-id/courses/:course-id/sections/:section-id
export interface CourseSectionDocData {
  section: number;
}
//
// /root/schools/:school-id/terms/:term-id/courses/:course-id
export interface CourseDocData {
  schoolID: string;
  fullTitle: string;
  name: {
    fullName: string;
    subject: string;
    number: string;
  };
  description: string;
  sectionData: CourseSectionDocData[];
}
export interface CourseDoc extends CourseDocData {
  ID: string;
}
//
// /root/schools/:school-id/terms/:term-id
export interface SchoolTermDocData extends TermInfo {
  termName: string;
  termID: string;
  termData: Object;
  order: number;
}
export interface SchoolTermDoc extends SchoolTermDocData {
  ID: string;
}

//
// /root/schools/:school-id/termsCacheTwo/:term-id
export interface SchoolTermCacheTwoDocData extends TermInfo {
  termID: string;
  termName: string;
  termData: Object;
  order: number;
  courses: SchoolTermCacheTwoCourseData[];
}
export interface SchoolTermCacheTwoCourseData {
  ID: string;
  name: string;
}

//
// /root/schools/:school-id/termsCache/:term-id
export interface SchoolTermCacheDocData {
  termID: string;
  termName: string;
  termData: Object;
  order: number;
  courses: SchoolTermCacheCourseData[];
}
export interface SchoolTermCacheCourseData {
  ID: string;
  title: string;
  name: string;
}

// root/schools/:school-id/
export interface SchoolDocData {
  name: string;
  city: string;
  stateProvince: string;
  country: string;
  terms: SchoolTermDocData[];
}
export interface SchoolDoc extends SchoolDocData {
  ID: string;
}

export interface UserDocSchoolTermData extends SchoolTermDocData {
  courses: CourseDoc[];
}

export interface UserDoc {
  schoolID: string;
  name: string;
  email: string;
  UID: string;
  schoolEmailAddress: string | null;
  terms: UserDocSchoolTermData[];
  currentTermID: string;
  marketItems: MarketItem[];
  joinedDate: Date;
}

export interface TermInfo {
  termID: string;
  termName: string;
}

export interface TermTextbooksCache extends TermInfo {
  textbooks: TextbookData[];
  termID: string;
  termName: string;
  //length of array is the number of textbooks used at that school
  //each textbook only appears once
}

export interface CourseSectionTextbookIDsMap {
  textbookIDs: string[];
  sectionName: string;
  courseName: string;
  courseID: string;
  // sectionID: string;
}

//schools/:schoolID/termCourseSectionTextbooks/:termID
export interface TermCourseSectionTextbooksCache extends TermInfo {
  textbooks: CourseSectionTextbookIDsMap[];
  termID: string;
  termName: string;
  //length of array is total number of sections at the school
  //each textbook ID can appear more than once
}
export interface TextbookData {
  textbookID: string;
  ISBN: string;
  hasISBN: boolean;
  badge: string;
  itemTitle: string;
  itemAuthor: string;
  itemBNCollegeURL: string;
  itemPrices: {
    type: string;
    prices: [
      { price: number; subtype: string },
      { price: number; subtype: string }
    ];
  }[];
  primaryImage: {
    exists: boolean;
    format: string;
    url: string;
  };
}

export const formatBookAuthor = (author: string) =>
  author
    .split(" ")
    .map((str) => str[0].toUpperCase() + str.substring(1))
    .join(" ");

export interface AllTextbookData {
  textbookID: string;
  ISBN: string;
  hasISBN: boolean;
  itemTitle: string;
  itemAuthor: string;
  itemBNCollegeURLs: {
    [college: string]: string;
  };
  itemPrices: {
    [college: string]: {
      type: string;
      prices: [
        { price: number; subtype: string },
        { price: number; subtype: string }
      ];
    }[];
  };
  itemImages: {
    [college: string]: {
      format: string;
      url: string;
    };
  };
  primaryImage: {
    format: string;
    url: string;
  } | null;
}

export type BookCondition = "VERY_GOOD" | "GOOD" | "ACCEPTABLE";

export const BOOK_CONDITIONS: {
  type: BookCondition;
  name: string;
  description: string;
}[] = [
  // {
  //   type: "LIKE_NEW",
  //   name: "Like New",
  //   description:
  //     "Little or no visible wear on the book cover or inside pages. No writing or highlighting on the inside pages.",
  // },
  {
    type: "VERY_GOOD",
    name: "Very Good",
    description:
      "None or slight wear on the book cover or inside pages. None or barely noticeable writing on only a few of the inside pages.",
  },
  {
    type: "GOOD",
    name: "Good",
    description:
      "Some wear on the book cover or inside pages. Some writing on some of the inside pages without obscuring the book text.",
  },
  {
    type: "ACCEPTABLE",
    name: "Acceptable",
    description:
      "Book has a lot of wear on the cover or inside pages, and/or the book has a lot of writing on most of the inside pages which may obscure the book text.",
  },
];

export const getBookCondition = (type: BookCondition) =>
  BOOK_CONDITIONS.filter((cond) => cond.type === type)[0];

export type MarketItemType =
  | "all"
  | "textbooks"
  | "books"
  | "apartments"
  | "houses"
  | "clothing"
  | "accessories"
  | "bikes"
  | "backpacks"
  | "other";

export interface MarketItemCreationData {
  sellerUID: string;
  sellerName: string;
  schoolID: string;
  itemType: MarketItemType;
  imageURLs: string[];
  description: string;
}

export interface MarketItem {
  marketItemID: string;
  sellerUID: string;
  sellerName: string;
  schoolID: string;
  postedDate: Date;
  updatedDates?: Date[];
  itemType: MarketItemType;
  imageURLs: string[];
  description: string;
}

export const marketItemFromLocalStorage = (item: Object): MarketItem => {
  return {
    ...(item as MarketItem),
    postedDate: new Date((item as MarketItem).postedDate),
  };
};

export const marketItemFromDB = (item: Object): MarketItem => {
  return {
    ...(item as MarketItem),
    postedDate: ((item as any).postedDate as Timestamp).toDate(),
  };
};

export interface TextbookInfo {
  title: string;
  author: string | null;
  ISBN: string | null;
}

export interface TextbookMarketItem extends MarketItem {
  textbookID: string | null;
  itemType: "textbooks";
  textbookInfo: TextbookInfo | null;
  textbookData: TextbookData | null;
  price: number;
  bookCondition: BookCondition;
}

export interface TextbookMarketItemCreationData extends MarketItemCreationData {
  textbookID: string | null;
  itemType: "textbooks";
  textbookInfo: TextbookInfo | null;
  textbookData: TextbookData | null;
  price: number;
  bookCondition: BookCondition;
}
