import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBrMaICOtRMVp3gV9mzeSZX81KGQFVuQJI",
  authDomain: "getplaza.app",
  projectId: "plaza-platform",
  storageBucket: "plaza-platform.appspot.com",
  messagingSenderId: "192505614687",
  appId: "1:192505614687:web:d4c97d21ab048af7c9a6ea",
  measurementId: "G-5THMRX2R8N",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
