import {
  collectionGroup,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar, { MobileBottomTabBar } from "../../components/Navbar";
import { db } from "../../firebase";
import {
  formatBookAuthor,
  getBookCondition,
  MarketItem,
  marketItemFromDB,
  marketItemFromLocalStorage,
  TextbookMarketItem,
} from "../../models";
import { getDurationText } from "../home/HomePage";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const marketItemPageLoader = async () => {};

export const shareTextbookPostLink = (marketItemID: string) => {
  const hostname = window.location.hostname;
  if (navigator.share) {
    navigator
      .share({
        // title: "web.dev",
        // text: "Check out web.dev.",
        url: `https://${hostname}/market/${marketItemID}`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
};

const MarketItemPage = () => {
  const { marketItemID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [marketItem, setMarketItem] = React.useState<MarketItem | null>(null);

  const [itemDeleted, setItemDeleted] = React.useState(false);

  React.useEffect(() => {
    const marketItemsQuery = query(
      collectionGroup(db, "marketItems"),
      where("marketItemID", "==", marketItemID)
    );
    getDocs(marketItemsQuery)
      .then((snap) => {
        console.log("found snap", snap.docs.length);
        if (snap.docs.length > 1) {
          throw "ERROR FOUND TWO ITEMS WITH THE SAME ID";
        } else if (snap.docs.length === 0) {
          setItemDeleted(true);
        } else {
          const item = snap.docs[0].data() as MarketItem;
          setMarketItem(item);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }, []);

  React.useEffect(() => {
    document.title = "Textbook For Sale - Plaza";
  }, []);

  console.log("itemDeleted", itemDeleted);

  if (itemDeleted) {
    return (
      <div>
        <Navbar overrideTitle="Textbook For Sale" />

        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>
        <div className="container">
          <div className="mt-5 d-flex flex-column justify-content-center align-items-stretch">
            <p className="h2 mb-4">
              This item was deleted by the seller, or it simply never existed
            </p>

            <p>Find another post for this textbook on the Textbooks Market</p>
            <Link className="btn btn-primary" to="/market">
              Go to Textbook Marketplace
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (!marketItem) {
    return (
      <div>
        <Navbar overrideTitle="Textbook For Sale" />

        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>

        <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
          <div>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (marketItem.itemType !== "textbooks") {
    return (
      <div>
        <Navbar overrideTitle="Textbook For Sale" />
        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>

        <div className="container mt-3">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Something went wrong</h4>
              <p className="mb-0">This is not a textbook</p>
            </div>
          </div>
          <div className="d-flex flex-row mt-4">
            <Link className="btn btn-primary flex-fill" to="/market">
              Go Back to Textbooks Market
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const bookRaw = marketItem as TextbookMarketItem;
  const book = marketItemFromDB(bookRaw) as TextbookMarketItem;

  const timeAgoText = getDurationText(new Date(), book.postedDate, true, [
    "days",
    "hours",
    null,
  ]);

  console.log(book);
  console.log("render", location.state);

  return (
    <div>
      <Navbar overrideTitle="Textbook For Sale" />
      <div className="fixed-bottom border-top">
        <MobileBottomTabBar />
      </div>

      {location.state?.showBack && (
        <div className="py-3 d-none d-sm-block">
          <div className="container">
            <div
              className="d-flex flex-row align-items-center"
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              <ArrowBackIcon />
              <p className="h6 ms-3 mb-0">Go Back</p>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        {book.textbookData && (
          <React.Fragment>
            <h4 className="card-title mb-0">{book.textbookData.itemTitle}</h4>
            <p className="mb-0 text-muted">
              {formatBookAuthor(book.textbookData.itemAuthor)}
            </p>
            <p className="mb-2 text-muted">ISBN-13: {book.textbookData.ISBN}</p>
          </React.Fragment>
        )}

        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <p className="h4 mb-0">${book.price}</p>

          <button
            className="btn btn-primary d-lg-none"
            onClick={() => shareTextbookPostLink(marketItem.marketItemID)}
          >
            Share Link
          </button>
        </div>

        <div className="card">
          <div className="card-body">
            <p className="h6">
              Condition: {getBookCondition(book.bookCondition).name}
            </p>
            <p className="mb-0 text-muted">
              {getBookCondition(book.bookCondition).description}
            </p>
          </div>
        </div>

        {book.description && book.description > "" && (
          <p className="mt-3">{book.description}</p>
        )}

        <p className="my-4 text-muted">
          Posted by <span className="text-dark">{book.sellerName}</span> on{" "}
          {book.postedDate.toDateString()} •{" "}
          {timeAgoText ? timeAgoText + " ago" : "Just Now"}
        </p>

        <div className="d-flex flex-column flex-md-row">
          <button className="btn btn-dark flex-fill me-md-3 mb-3 mb-md-0">
            Reserve Textbook - Coming Early Jan 2023
          </button>
          <button className="btn btn-outline-dark flex-fill">
            Message Seller - Coming Early Jan 2023
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketItemPage;
