import React, { useState } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Navbar, {
  MobileBottomTabBar,
  signInWithGoogleHandler,
} from "../../components/Navbar";

import {
  AppContext,
  LoadingState,
  LoadingTermsState,
  DynamicObj,
} from "../../AppState";

import { UserContext } from "../../UserState";

import StorefrontIcon from "@mui/icons-material/Storefront";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CottageIcon from "@mui/icons-material/Cottage";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import WatchIcon from "@mui/icons-material/Watch";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import BackpackIcon from "@mui/icons-material/Backpack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ClearIcon from "@mui/icons-material/Clear";

import "./MarketPage.css";
import {
  SchoolTermCacheTwoCourseData,
  CourseSectionTextbookIDsMap,
  TermCourseSectionTextbooksCache,
  TextbookMarketItem,
  getBookCondition,
  formatBookAuthor,
} from "../../models";
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  limit,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Link, useLocation } from "react-router-dom";
import {
  getFromBreakpoint,
  getViewportWidth,
  MaxWidthProps,
} from "../root/RootPage";
import { getDurationText } from "../home/HomePage";

export const marketPageLoader = async () => {};

interface ItemCategory {
  name: string;
  id: string;
  icon: React.ReactNode;
  [otherOptions: string]: unknown;
}

const ITEM_CATEGORIES: ItemCategory[] = [
  {
    name: "All",
    id: "all",
    icon: <StorefrontIcon />,
  },
  {
    name: "Textbooks",
    id: "textbooks",
    icon: <LibraryBooksIcon />,
  },
  {
    name: "Books",
    id: "books",
    icon: <MenuBookIcon />,
  },
  {
    name: "Apartments",
    id: "apartments",
    icon: <ApartmentIcon />,

    types: [
      {
        name: "Re-Lets",
        id: "apt-re-let",
      },
      {
        name: "Sublease",
        id: "apt-sublease",
      },
    ],
  },
  {
    name: "Houses",
    id: "houses",
    icon: <CottageIcon />,
  },
  {
    name: "Clothing",
    id: "clothing",
    icon: <CheckroomIcon />,
    brands: [
      {
        name: "Nike",
        id: "nike",
      },
      {
        name: "Forever 21",
        id: "forever-21",
      },
      {
        name: "Next Level",
        id: "next-level",
      },
      {
        name: "Old Navy",
        id: "old-navy",
      },
      {
        name: "Shein",
        id: "shein",
      },
      {
        name: "American Eagle",
        id: "american-eagle",
      },
      {
        name: "Canvas",
        id: "canvas",
      },
      {
        name: "Comfort Colors",
        id: "comfort-colors",
      },
      {
        name: "Capezic",
        id: "capezic",
      },
      {
        name: "Express",
        id: "express",
      },
      {
        name: "Fruit of the Loom",
        id: "cookie-street",
      },
      {
        name: "Hollister",
        id: "hollister",
      },
      {
        name: "Lululemon",
        id: "lululemon",
      },
      {
        name: "TAMU",
        id: "tamu",
      },
      {
        name: "Workshop",
        id: "workshop",
      },
      {
        name: "Dockers",
        id: "dockers",
      },
    ],
    types: [
      {
        name: "Short Sleeve Shirts",
        id: "short-sleve-shirts",
      },
      {
        name: "Long Sleeve Shirts",
        id: "long-sleeve-shirts",
      },
      {
        name: "Blouses",
        id: "blouses",
      },
      {
        name: "Tank Tops",
        id: "tank-tops",
      },
      {
        name: "Gym Tanks",
        id: "gym-tanks",
      },
      {
        name: "Jackets",
        id: "jackets",
      },
      {
        name: "Sweaters",
        id: "sweaters",
      },
      {
        name: "Dresses",
        id: "dresses",
      },
      {
        name: "Skirts",
        id: "skirts",
      },
      {
        name: "Tennis Shoes",
        id: "tennis-shoes",
      },
    ],
  },
  {
    name: "Accessories",
    id: "accessories",
    icon: <WatchIcon />,
    types: [
      {
        name: "Sunglasses",
        id: "sunglasses",
      },
      {
        name: "Hats",
        id: "hats",
      },
      {
        name: "Jewelry",
        id: "jewelry",
      },
    ],
  },
  {
    name: "Bikes",
    id: "bikes",
    icon: <DirectionsBikeIcon />,
    types: [
      {
        name: "Mountain Bike",
        id: "mountain-bike",
      },
      {
        name: "Road Bike",
        id: "road-bike",
      },
    ],
  },
  {
    name: "Backpacks",
    id: "backpacks",
    icon: <BackpackIcon />,
  },
  {
    name: "Other",
    id: "other",
    icon: <MoreHorizIcon />,
    types: [
      {
        name: "Bedding",
        id: "bedding",
      },
    ],
  },
];

interface SelectCategoryBottomNavbarMobileProps {
  selectedCategoryID: string;
  setSelectedCategoryID: (itemID: string) => void;
  getSelectedCategoryObj: (itemID: string) => ItemCategory;
}

const SelectCategoryBottomNavbarMobile = ({
  selectedCategoryID,
  setSelectedCategoryID,
  getSelectedCategoryObj,
}: SelectCategoryBottomNavbarMobileProps) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawerOpen = () => setDrawerOpen((st) => !st);

  return (
    <nav className="navbar bg-white d-sm-none">
      <div className="container-fluid">
        <div className="d-flex flex-column justify-content-center">
          <small className="mb-0 text-muted">Filter Category</small>
          <p className="fw-bold mb-0 h4">
            {getSelectedCategoryObj(selectedCategoryID).name}
          </p>
        </div>

        <button
          className="btn btn-dark bg-dark text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#bottomNavbar"
          aria-controls="bottomNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleDrawerOpen}
        >
          {drawerOpen ? <span>Close</span> : <span>Open</span>}
          {drawerOpen ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
        </button>
        <div className="collapse navbar-collapse" id="bottomNavbar">
          <ul className="navbar-nav nav-pills flex-column">
            {ITEM_CATEGORIES.map((e, i) => {
              let marginClass = "";
              if (i === 0) marginClass = "mt-2";
              else if (i === ITEM_CATEGORIES.length - 1) marginClass = "mb-2";

              const isSelected = selectedCategoryID === e.id;
              return (
                <li
                  className={`nav-item rounded text-dark px-3 py-2 ${marginClass} ${
                    isSelected ? "bg-primary text-white" : ""
                  }`}
                  key={e.id}
                  onClick={() => setSelectedCategoryID(e.id)}
                  role="button"
                >
                  {e.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

interface SelectCategoryDesktopProps {
  selectedCategoryID: string;
  setSelectedCategoryID: (itemID: string) => void;
  getSelectedCategoryObj: (itemID: string) => ItemCategory;
}

const SelectCategoryDesktop = ({
  selectedCategoryID,
  setSelectedCategoryID,
  getSelectedCategoryObj,
}: SelectCategoryDesktopProps) => {
  return (
    <div className="d-none d-sm-flex flex-row justify-content-center align-items-center bg-light">
      <div
        className="flex-fill d-flex flex-row justify-content-md-center align-items-center hide-scrollbar"
        style={{ overflow: "scroll" }}
      >
        {ITEM_CATEGORIES.map((item, i) => {
          const className1 = i === 0 ? " ms-3 " : "";
          const className2 = i === ITEM_CATEGORIES.length - 1 ? " me-3 " : "";

          const isActive = selectedCategoryID == item.id;

          // const className3 = isActive ? "btn btn-dark" : "btn btn-light";
          const className3 = isActive
            ? " border-bottom border-dark border-2 text-dark "
            : " text-muted border-bottom border-2 border-light";

          return (
            <div
              className={`py-3 px-3${className1}${className2}${className3} d-flex flex-column justify-content-center align-items-center MarketPage-category`}
              onClick={() => setSelectedCategoryID(item.id)}
              role="button"
              key={item.id}
            >
              <p className="mb-2" style={{ fontSize: 20 }}>
                {item.icon}
              </p>
              <p className="mb-0">{item.name}</p>
            </div>
          );
        })}
      </div>

      <div
        className="d-flex d-md-none px-2 flex-column justify-content-center align-items-center"
        id="MarketPage-see-more"
      >
        <KeyboardArrowRightIcon />
      </div>
    </div>
  );
};

interface SelectSchoolProps {
  value: string;
  setValue: (newVal: string) => void;
}

const SelectSchool = ({ value, setValue }: SelectSchoolProps) => {
  const [selectedSchoolID, setSelectedSchoolID] = React.useState(value);

  return (
    <div className="d-flex flex-row">
      <select
        className="form-select"
        aria-label="Select School"
        value={selectedSchoolID}
        onChange={(e) => setSelectedSchoolID(e.target.value)}
      >
        <option value="">Select School</option>
        <option value={"4tH0YeST8WR622vkxMP0"}>Texas A&M</option>
      </select>
      {selectedSchoolID !== "" ? (
        <button
          className="btn btn-dark ms-2"
          onClick={() => setValue(selectedSchoolID)}
        >
          Select
        </button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export const TextbookInfoMarketRowItem = ({
  book,
}: {
  book: TextbookMarketItem;
}) => {
  const now = new Date();
  const timeAgoText = getDurationText(now, book.postedDate, false, [
    "days",
    "hours",
    null,
  ]);
  return (
    <Link
      to={`/market/${book.marketItemID}`}
      state={{ showBack: true }}
      className="text-decoration-none text-dark"
      style={{ cursor: "pointer" }}
    >
      <div className="card mb-2">
        <div className="card-body">
          <div className="align-middle">
            <p className="d-inline-block mb-0 me-2 h6">
              ${book.price} • {getBookCondition(book.bookCondition).name}
            </p>
          </div>

          <small className="text-muted lh-sm">
            {timeAgoText ? timeAgoText + " ago" : "Just Now"}
          </small>
        </div>
      </div>
    </Link>
  );
};

const TextbookInfoMarketRow = ({ textbookID }: { textbookID: string }) => {
  const { pathname } = useLocation();
  const isLanding = pathname === "/";

  const tamuID = "4tH0YeST8WR622vkxMP0";

  const { marketItems, loadTextbookMarketItems } = React.useContext(AppContext);
  const { userDoc } = React.useContext(UserContext);

  const vw = getViewportWidth();
  const { value: defaultLimit } = getFromBreakpoint<number>(vw, {
    xs: isLanding ? 2 : 4,
    sm: isLanding ? 2 : 4,
    md: isLanding ? 4 : 6,
    lg: isLanding ? 4 : 8,
    xl: isLanding ? 4 : 12,
    xxl: isLanding ? 4 : 12,
  }) as { value: number };
  const { value: limitStep } = getFromBreakpoint<number>(vw, {
    xs: 4,
    sm: 4,
    md: isLanding ? 4 : 6,
    lg: isLanding ? 4 : 8,
    xl: isLanding ? 6 : 12,
    xxl: isLanding ? 6 : 12,
  }) as { value: number };
  const [limitItems, setLimitItems] = React.useState(defaultLimit);

  const [loadedItems, setLoadedItems] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);

  const [moreItemsExist, setMoreItemsExist] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState<number | null>(null);

  const getHourFromNow = () => {
    const now = new Date();
    return new Date(now.getTime() + 3600000);
  };

  const loadMoreTextbooks = () => setLoadingMore(true);

  const renderShowMoreButton = () => {
    const shouldRender = totalItems === null || limitItems < totalItems;

    if (!shouldRender) return <span></span>;

    let labelText: string = limitStep.toString();

    if (
      shouldRender &&
      totalItems !== null &&
      totalItems - limitStep < limitStep
    ) {
      labelText = (totalItems - limitStep).toString();
      console.log("minus", totalItems, limitStep);
    }
    return (
      <button
        className="btn btn-link text-decoration-none"
        onClick={loadMoreTextbooks}
      >
        Show {labelText} more
      </button>
    );
  };

  const renderShowingText = () => {
    let labelText: string = limitItems.toString();
    if (marketItems[tamuID]) {
      const bookMarketItems = marketItems[tamuID].filter((item) => {
        return (item as TextbookMarketItem).textbookID === textbookID;
      });
      labelText = bookMarketItems.length.toString();
    }
    return (
      <span>
        Showing {labelText}
        {totalItems ? ` of ${totalItems} ` : ""} items
      </span>
    );
  };

  // //debugging
  // React.useEffect(() => {
  //   console.log("marketItems", marketItems);
  // }, [marketItems]);

  //initial load
  React.useEffect(() => {
    if (!loadedItems) {
      const hrFromNow = getHourFromNow();
      loadTextbookMarketItems(tamuID, textbookID, defaultLimit, hrFromNow).then(
        async (newMarketItems) => {
          setLoadedItems(true);
          if (newMarketItems.length < 4) {
            setMoreItemsExist(false);
            setTotalItems(newMarketItems.length);
          } else {
            //there could be more items, execute a count query
            const marketItemsColl = collection(
              db,
              `schools/${tamuID}/marketItems`
            );
            const countQuery = query(
              marketItemsColl,
              where("textbookID", "==", textbookID)
            );
            getCountFromServer(countQuery).then((snap) => {
              setMoreItemsExist(true);
              setTotalItems(snap.data().count);
            });
          }
        }
      );
    }
  }, [userDoc]);

  //load more loader
  React.useEffect(() => {
    if (loadedItems && loadingMore && moreItemsExist) {
      console.log("limits", defaultLimit, limitItems);

      const bookMarketItems = marketItems[tamuID]
        .filter((item) => {
          return item.itemType === "textbooks";
        })
        .map((item) => item as TextbookMarketItem)
        .filter((bk) => bk.textbookID === textbookID)
        .sort((a, b) => b.postedDate.getTime() - a.postedDate.getTime());
      const shownItems = bookMarketItems.slice(0, limitItems);
      const lastItem = shownItems[shownItems.length - 1];
      console.log("lastItem", lastItem);

      console.log("current market items", marketItems);
      loadTextbookMarketItems(
        tamuID,
        textbookID,
        defaultLimit,
        lastItem.postedDate
      ).then((newMarketItems) => {
        console.log(lastItem.postedDate);
        if (newMarketItems.length === 0) {
          setMoreItemsExist(false);
        } else {
          setMoreItemsExist(true);
          setLimitItems((st) => st + limitStep);
        }
        setLoadingMore(false);
      });
    }
  }, [loadingMore]);

  if (!loadedItems) {
    return <span></span>;
  }

  const allTextbooks = marketItems[tamuID]
    .filter((item) => {
      return item.itemType === "textbooks";
    })
    .map((item) => item as TextbookMarketItem);

  const books = allTextbooks.filter((bk) => bk.textbookID === textbookID);

  const sortedBooks = books.sort(
    (a, b) => b.postedDate.getTime() - a.postedDate.getTime()
  );

  const colWidths = isLanding ? "col-md-6" : "col-md-4 col-lg-3 col-xxl-2";

  return (
    <div className="row">
      {sortedBooks.slice(0, limitItems).map((bk) => {
        // if (!bk.textbookData) {
        //   return (
        //     <div className="p-2 border rounded me-2" key={bk.marketItemID}>
        //       <p className="fw-bold mb-0" style={{ whiteSpace: "nowrap" }}>
        //         {getBookCondition(bk.bookCondition).name}
        //       </p>
        //     </div>
        //   );
        // }

        // const imgsrc =
        //   "https://media.gettyimages.com/id/157482029/photo/stack-of-books.jpg?" +
        //   "s=612x612&w=gi&k=20&c=_Yaofm8sZLZkKs1eMkv-zhk8K4k5u0g0fJuQrReWfdQ=";

        return (
          <div
            className={`col-6 ${colWidths}`}
            style={{ cursor: "pointer" }}
            key={bk.marketItemID}
          >
            <TextbookInfoMarketRowItem book={bk} />
          </div>
        );
      })}

      <div>
        {moreItemsExist && (
          <div className="d-flex flex-row justify-content-center align-items-center">
            {renderShowingText()}
            {renderShowMoreButton()}
          </div>
        )}
      </div>
    </div>
  );
};

const TextbookInfoRow = ({
  textbookID,
  schoolID,
  termID,
}: {
  textbookID: string;
  schoolID: string;
  termID: string;
}) => {
  const { termTextbooksCache, getTermTextbooksCacheForTerm } =
    React.useContext(AppContext);

  const term = getTermTextbooksCacheForTerm(
    termTextbooksCache[schoolID],
    termID
  );
  if (!term) {
    throw "TextbookInfoRow, term is null";
  }

  const book = term.textbooks.filter((bk) => bk.textbookID === textbookID)[0];

  return (
    <div className="py-2">
      <div>{book.itemTitle}</div>
      <div className="text-muted">{formatBookAuthor(book.itemAuthor)}</div>
    </div>
  );
};

interface CourseTextbooksSectionProps {
  sectionTextbooks: CourseSectionTextbookIDsMap[];
  schoolID: string;
  termID: string;
}

const CourseTextbooksSection = ({
  sectionTextbooks,
  schoolID,
  termID,
}: CourseTextbooksSectionProps) => {
  const { courseName } = sectionTextbooks[0];
  const sorted = sectionTextbooks.sort(
    (a, b) => Number(a.sectionName) - Number(b.sectionName)
  );

  let sectionGroups: {
    [concatBkIDs: string]: {
      [leadDigit: string]: CourseSectionTextbookIDsMap[];
    };
  } = {};

  sorted.forEach((s) => {
    const concatSortedBkIDs = s.textbookIDs.sort().join("");
    const leadDigit = s.sectionName[0];
    if (!sectionGroups[concatSortedBkIDs]) {
      sectionGroups[concatSortedBkIDs] = {};
    }
    if (!sectionGroups[concatSortedBkIDs][leadDigit]) {
      sectionGroups[concatSortedBkIDs][leadDigit] = [];
    }

    sectionGroups[concatSortedBkIDs][leadDigit].push(s);
    sectionGroups[concatSortedBkIDs][leadDigit].sort(
      (a, b) => Number(a.sectionName) - Number(b.sectionName)
    );
  });

  return (
    <div className="mb-4">
      <h5 className="mb-0">{courseName}</h5>
      {Object.keys(sectionGroups).map((concatBkIDs) => {
        const sectionsGroup = sectionGroups[concatBkIDs];

        let sectionsGroupTextbooksIDs: string[] = [];
        Object.keys(sectionsGroup).forEach((leadDigit) => {
          const sectionsBkIDs = sectionsGroup[leadDigit]
            .map((s) => s.textbookIDs)
            .flat();
          sectionsGroupTextbooksIDs = [
            ...sectionsGroupTextbooksIDs,
            ...sectionsBkIDs,
          ];
        });
        sectionsGroupTextbooksIDs = Array.from(
          new Set(sectionsGroupTextbooksIDs)
        );

        const sectionsLabelText =
          sectionTextbooks.length > 1 ? "Sections" : "Section ";
        return (
          <div key={concatBkIDs} className="mb-2">
            <div>
              <span className="d-inline-block">{sectionsLabelText}</span>
              {Object.keys(sectionsGroup).map((leadingDigit) => {
                const sections = sectionsGroup[leadingDigit].map(
                  (s) => s.sectionName
                );
                const sectionsShortened =
                  sections.length === 1
                    ? sections[0]
                    : `${sections[0]}-${sections[sections.length - 1]}`;
                return (
                  <span className="ms-2" key={sectionsShortened}>
                    {sectionsShortened}
                  </span>
                );
              })}
            </div>
            {sectionsGroupTextbooksIDs.map((bkID) => {
              return (
                <div key={bkID}>
                  <TextbookInfoRow
                    textbookID={bkID}
                    schoolID={schoolID}
                    termID={termID}
                  />
                  <TextbookInfoMarketRow textbookID={bkID} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const AllMarket = () => {
  return <div></div>;
};

interface CourseDeptSliderProps {
  courseNumbers: string[];
  depts: string[];
  courseTextbooksObj: { [courseName: string]: CourseSectionTextbookIDsMap[] };
  selectedCourseDept: string;
  setSelectedCourseDept: (newDept: string) => void;
  selectedCourseNumber: string;
  setSelectedCourseNumber: (newNum: string) => void;
}

const CourseDeptSlider = ({
  courseNumbers,
  depts,
  courseTextbooksObj,
  selectedCourseDept,
  setSelectedCourseDept,
  selectedCourseNumber,
  setSelectedCourseNumber,
}: CourseDeptSliderProps) => {
  return (
    <div>
      <div
        className="d-flex d-md-none flex-row justify-content-start align-items-center my-3 hide-scrollbar"
        style={{ overflow: "scroll" }}
      >
        {depts.map((deptName) => {
          const deptCourses = getCourseNumbers(courseTextbooksObj, deptName);

          const selected = deptName === selectedCourseDept;

          const showIcon = selected ? (
            <ClearIcon className="d-inline ms-2" />
          ) : (
            <span></span>
          );
          return (
            <button
              key={deptName}
              className={`p-2 me-2 ${
                selected
                  ? "btn btn-dark text-white"
                  : "btn btn-light bg-white border"
              } d-flex flex-row justify-content-center align-items-center`}
              onClick={() => {
                const newDeptName = selected ? "" : deptName;
                setSelectedCourseDept(newDeptName);
                setSelectedCourseNumber("");
              }}
            >
              <span>{deptName}</span>
              <span
                className={`btn ms-2 d-inline rounded border ${
                  selected
                    ? "btn-dark bg-dark border-dark"
                    : "btn-light bg-light"
                }`}
                style={{ padding: "2px 5px" }}
              >
                {deptCourses.length}
              </span>
              {showIcon}
            </button>
          );
        })}
      </div>
      {courseNumbers === null ? (
        <></>
      ) : (
        <div
          className="d-flex d-md-none flex-row justify-content-start align-items-center my-3 hide-scrollbar"
          style={{ overflow: "scroll" }}
        >
          {courseNumbers.map((number) => {
            const selected = number === selectedCourseNumber;

            const showIcon = selected ? (
              <ClearIcon className="d-inline ms-2" />
            ) : (
              <span></span>
            );
            return (
              <button
                key={`${selectedCourseDept}-${number}`}
                className={`btn p-2 me-2 ${
                  selected ? "btn-dark text-white" : "btn-light bg-white border"
                } d-flex justify-content-center align-items-center`}
                onClick={() => {
                  const newNumber = selected ? "" : number;
                  setSelectedCourseNumber(newNumber);
                }}
              >
                <span>{number}</span>
                <span>{showIcon}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

const getCourseNumbers = (
  courseTextbooksObj: {
    [courseName: string]: CourseSectionTextbookIDsMap[];
  },
  selectedDept: string
) => {
  return Object.keys(courseTextbooksObj)
    .filter((courseName) => courseName.split(" ")[0] === selectedDept)
    .map((courseName) => courseName.split(" ")[1]);
};

export const TextbooksMarket = ({
  schoolID,
  maxWidthProps,
}: {
  schoolID: string;
  maxWidthProps?: MaxWidthProps;
}) => {
  const {
    termTextbooksCache,
    termCourseSectionTextbooksCache,
    loadTermTextbooksCache,
    loadTermCourseSectionTextbooksCache,
    termTextbooksCacheLoaded,
    termCourseSectionTextbooksCacheLoaded,
    getTermCourseSectionTextbooksCacheForTerm,
    getTermTextbooksCacheForTerm,
    marketItems,
    loadAllMarketItems,
  } = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);

  const tamuID = "4tH0YeST8WR622vkxMP0";
  const spring2023TermID = "z9Y2f3XXpFDSXEdgsMRt";

  const textbooksCacheLoaded = termTextbooksCacheLoaded(
    termTextbooksCache[tamuID],
    spring2023TermID
  );

  const courseSectionTextbooksCacheLoaded =
    termCourseSectionTextbooksCacheLoaded(
      termCourseSectionTextbooksCache[tamuID],
      spring2023TermID
    );

  const [inputText, setInputText] = React.useState("");
  const [limitShown, setLimitShown] = React.useState(20);
  const increaseLimitShown = () => setLimitShown((st) => st + 20);
  // const [selectedCourseDept, setSelectedCourseDept] = React.useState("");
  // const [selectedCourseNumber, setSelectedCourseNumber] = React.useState("");

  const setInputTextOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value.toUpperCase());
    // if (selectedCourseDept.length > 0) {
    //   setSelectedCourseDept("");
    // }
    // if (selectedCourseNumber.length > 0) {
    //   setSelectedCourseNumber("");
    // }
  };

  const allLoaded =
    textbooksCacheLoaded &&
    courseSectionTextbooksCacheLoaded &&
    marketItems[tamuID];
  console.log("allLoaded", allLoaded);
  React.useEffect(() => {
    console.log("allLoaded effect", allLoaded);
    if (!allLoaded) {
      console.log("loading cache");
      loadTermCourseSectionTextbooksCache(tamuID, spring2023TermID);
      loadTermTextbooksCache(tamuID, spring2023TermID);
      loadAllMarketItems(tamuID);
    }
  }, [userContext.userDoc, loadTermCourseSectionTextbooksCache]);

  if (!allLoaded) {
    return (
      <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
        <div>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
  const currTermTextbooks = getTermCourseSectionTextbooksCacheForTerm(
    termCourseSectionTextbooksCache[tamuID],
    spring2023TermID
  );
  if (currTermTextbooks === null) {
    throw "TextbooksPage, term has not been loaded yet";
  }

  let courseTextbooksObj: {
    [courseName: string]: CourseSectionTextbookIDsMap[];
  } = {};
  currTermTextbooks.textbooks.forEach((sectionBooks) => {
    if (!courseTextbooksObj[sectionBooks.courseName]) {
      courseTextbooksObj[sectionBooks.courseName] =
        [] as CourseSectionTextbookIDsMap[];
    }

    courseTextbooksObj[sectionBooks.courseName].push(sectionBooks);
  });

  const alphaSorted = Object.keys(courseTextbooksObj).sort((a, b) => {
    const [aDept, aNumber] = a.split(" ");
    const [bDept, bNumber] = b.split(" ");
    if (aDept < bDept) {
      return -1;
    } else if (aDept > bDept) {
      return 1;
    } else if (aDept === bDept) {
      return Number(aNumber) - Number(bNumber);
    } else {
      return 0;
    }
  });

  let coursesSorted = alphaSorted;

  if (marketItems && marketItems[tamuID] && marketItems[tamuID].length > 0) {
    const hadTextbooks = alphaSorted.filter((courseN) => {
      const courseBkIDs = courseTextbooksObj[courseN]
        .map((map) => map.textbookIDs)
        .flat();

      const marketItemsForThisCourse = marketItems[tamuID].filter((item) =>
        courseBkIDs.includes((item as TextbookMarketItem).textbookID ?? "")
      );
      return marketItemsForThisCourse.length > 0;
    });
    const notHadTextbooks = alphaSorted.filter((courseN) => {
      return !hadTextbooks.includes(courseN);
    });

    coursesSorted = [...hadTextbooks, ...notHadTextbooks];
  }

  // const isLetter = (c: string) => c.toLowerCase() != c.toUpperCase();
  let shownCourses: string[] = coursesSorted;
  if (inputText.length > 0) {
    shownCourses = coursesSorted.filter((course) => {
      return course.toUpperCase().includes(inputText.toUpperCase());
    });
  }
  const limitedCourses = shownCourses.slice(0, limitShown);
  // else if (selectedCourseDept.length > 0) {
  //   if (selectedCourseNumber.length > 0) {
  //     shownCourses = coursesSorted.filter((course) => {
  //       const [dept, number] = course.split(" ");
  //       const deptMatch = dept === selectedCourseDept;
  //       const numberMatch = number === selectedCourseNumber;
  //       return deptMatch && numberMatch;
  //     });
  //   } else {
  //     shownCourses = coursesSorted.filter(
  //       (course) => course.split(" ")[0] === selectedCourseDept
  //     );
  //   }
  // }

  // let depts: string[] = Array.from(
  //   new Set(coursesSorted.map((c) => c.split(" ")[0]))
  // ).sort();

  // let courseNumbers = null;
  // if (selectedCourseDept !== "") {
  //   courseNumbers = getCourseNumbers(courseTextbooksObj, selectedCourseDept);
  // }

  return (
    <div>
      <div {...(maxWidthProps ?? {})}>
        <div className="d-flex flex-row justify-content-between align-items-end mb-2">
          <label htmlFor="filterCourseNameInput" className="form-label mb-0">
            Filter By Course Name
          </label>
        </div>
        <input
          type="text"
          className="form-control"
          id="filterCourseNameInput"
          placeholder="PHYS 206"
          value={inputText}
          onChange={setInputTextOnChange}
        />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between">
          <small className="text-muted">
            Searching for textbooks for Spring 2023 semester
          </small>
          <small className="text-muted">
            {coursesSorted.length} total courses
          </small>
        </div>
      </div>
      <div className="mt-2" style={{ marginBottom: "100px" }}>
        {limitedCourses.map((courseName) => {
          const sectionTextbooks = courseTextbooksObj[courseName];
          return (
            <CourseTextbooksSection
              sectionTextbooks={sectionTextbooks}
              schoolID={schoolID}
              termID={spring2023TermID}
              key={courseName}
            />
          );
        })}
        {limitedCourses.length < shownCourses.length && (
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between">
                <button
                  className="btn btn-dark shadow-sm me-2 flex-fill"
                  onClick={increaseLimitShown}
                >
                  Show 20 More
                </button>
                <button
                  className="btn btn-primary shadow-sm flex-fill"
                  onClick={() => setLimitShown(shownCourses.length)}
                >
                  Show All
                </button>
              </div>

              <div>
                Currently showing {limitedCourses.length} of{" "}
                {shownCourses.length} {inputText && "filtered"} courses
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const BooksMarket = () => {
  return <div></div>;
};
const ApartmentsMarket = () => {
  return <div></div>;
};
const HousesMarket = () => {
  return <div></div>;
};
const ClothingMarket = () => {
  return <div></div>;
};
const AccessoriesMarket = () => {
  return <div></div>;
};
const BikesMarket = () => {
  return <div></div>;
};
const BackpacksMarket = () => {
  return <div></div>;
};
const OtherMarket = () => {
  return <div></div>;
};

const MarketPage = () => {
  const { userDoc, signedIn } = React.useContext(UserContext);

  React.useEffect(() => {
    document.title = "Textbooks - Plaza";
  }, []);

  const defaultSchoolID = userDoc?.schoolID ?? "4tH0YeST8WR622vkxMP0";
  const [currSchoolID, setCurrSchoolID] = React.useState(defaultSchoolID);

  const [selectedCategoryID, setSelectedCategoryID] =
    React.useState("textbooks");

  const getSelectedCategoryObj = (itemID: string): ItemCategory => {
    const categories = ITEM_CATEGORIES.filter((cat) => cat.id === itemID);
    if (categories.length !== 1) {
      throw Error("Found More Than One Category");
    }
    return categories[0];
  };

  let bodyComponent;
  if (selectedCategoryID === "all") {
    bodyComponent = <AllMarket />;
  } else if (selectedCategoryID === "textbooks") {
    bodyComponent = <TextbooksMarket schoolID={defaultSchoolID} />;
  } else if (selectedCategoryID === "books") {
    bodyComponent = <BooksMarket />;
  } else if (selectedCategoryID === "apartments") {
    bodyComponent = <ApartmentsMarket />;
  } else if (selectedCategoryID === "houses") {
    bodyComponent = <HousesMarket />;
  } else if (selectedCategoryID === "clothing") {
    bodyComponent = <ClothingMarket />;
  } else if (selectedCategoryID === "accessories") {
    bodyComponent = <AccessoriesMarket />;
  } else if (selectedCategoryID === "bikes") {
    bodyComponent = <BikesMarket />;
  } else if (selectedCategoryID === "backpacks") {
    bodyComponent = <BackpacksMarket />;
  } else if (selectedCategoryID === "other") {
    bodyComponent = <OtherMarket />;
  }
  return (
    <div>
      <Navbar />
      {/* <SelectCategoryDesktop
        selectedCategoryID={selectedCategoryID}
        setSelectedCategoryID={setSelectedCategoryID}
        getSelectedCategoryObj={getSelectedCategoryObj}
      /> */}
      <div className="fixed-bottom border-top">
        {/* <SelectCategoryBottomNavbarMobile
          selectedCategoryID={selectedCategoryID}
          setSelectedCategoryID={setSelectedCategoryID}
          getSelectedCategoryObj={getSelectedCategoryObj}
        /> */}
        <MobileBottomTabBar />
      </div>
      <div className="container mt-sm-4">
        {!signedIn ? (
          <p className="mb-2 text-muted ">
            Sign in with Google to post your textbook for sale
          </p>
        ) : (
          <span></span>
        )}

        {bodyComponent}
      </div>
    </div>
  );
};

export default MarketPage;
