import React from "react";

import { UserContext, UserData } from "../../UserState";
import Navbar, {
  LandingNavbar,
  signInWithGoogleHandler,
} from "../../components/Navbar";
import "./RootPage.css";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { TextbooksMarket } from "../market/MarketPage";
import { GiftCardGiveawayCard } from "../home/HomePage";
import { UserDoc } from "../../models";

const tamubgimage =
  "https://today.tamu.edu/wp-content/uploads/2019/10/20190806_TAMU_Arerial_brs0070-Edit-2400.jpg";

const supportedUniversities = [
  {
    name: "Texas A&M - College Station",
    shortName: "Texas A&M",
    schoolID: "4tH0YeST8WR622vkxMP0",
    selectedColor: "#500000",
    backgroundImageURL:
      "https://today.tamu.edu/wp-content/uploads/2019/10/20190806_TAMU_Arerial_brs0070-Edit-2400.jpg",
  },
  // {
  //   name: "University of Texas at Austin",
  //   shortName: "UT Austin",
  //   schoolID: "a",
  //   selectedColor: "#bf5700",
  //   backgroundImageURL:
  //     "https://sites.utexas.edu/texonomics/files/2017/12/xlarge_utexas.jpg",
  // },
  // {
  //   name: "Texas Tech - Lubbock",
  //   shortName: "Texas Tech",
  //   schoolID: "b",
  //   selectedColor: "#CC0000",
  //   backgroundImageURL:
  //     "https://www.texastechalumni.org/s/1422/images/gid1001/editor/2022/hero-bg.jpeg",
  // },
  // {
  //   name: "University of Texas at Dallas",
  //   shortName: "UT Dallas",
  //   schoolID: "c",
  //   selectedColor: "#e87500",
  //   backgroundImageURL:
  //     "https://www.commonapp.org/static/fcdecd5f87869b5df17b3f610632a0a3/university-texas-dallas_1039.jpg",
  // },
  // {
  //   name: "University of Texas at San Antonio",
  //   shortName: "UT San Antonio",
  //   schoolID: "d",
  //   selectedColor: "#F15A22",
  //   backgroundImageURL:
  //     "https://www.utsa.edu/_files/locations/images/content/downtown-campus.png",
  // },
];

const codes = [
  "AALO",
  "ACCT",
  "AERO",
  "AERS",
  "AFST",
  "AGCJ",
  "AGEC",
  "AGLS",
  "AGSC",
  "AGSM",
  "ALEC",
  "ALED",
  "ANSC",
  "ANTH",
  "ARAB",
  "ARCH",
  "AREN",
  "ARTS",
  "ASCC",
  "ASIA",
  "ASTR",
  "ATMO",
  "ATTR",
  "BAEN",
  "BEFB",
  "BESC",
  "BICH",
  "BIMS",
  "BIOL",
  "BMEN",
  "BOTN",
  "BUSN",
  "CARC",
  "CEHD",
  "CHEM",
  "CHEN",
  "CHIN",
  "CLAS",
  "CLEN",
  "COMM",
  "COSC",
  "CSCE",
  "CVEN",
  "CYBR",
  "DAEN",
  "DASC",
  "DCED",
  "DDHS",
  "DIVE",
  "ECCB",
  "ECEN",
  "ECHE",
  "ECMT",
  "ECON",
  "EDCI",
  "EHRD",
  "ENDG",
  "ENDS",
  "ENGL",
  "ENGR",
  "ENST",
  "ENTC",
  "ENTO",
  "EPFB",
  "EPSY",
  "ESET",
  "ESSM",
  "EURO",
  "EVEN",
  "FILM",
  "FINC",
  "FIVS",
  "FREN",
  "FRSC",
  "FSCI",
  "FSTC",
  "FYEX",
  "GALV",
  "GENE",
  "GEOG",
  "GEOL",
  "GEOP",
  "GEOS",
  "GERM",
  "HBRW",
  "HEFB",
  "HHUM",
  "HISP",
  "HIST",
  "HLTH",
  "HORT",
  "HUMA",
  "IBUS",
  "IDIS",
  "INST",
  "INTS",
  "ISEN",
  "ISTM",
  "ITAL",
  "ITDE",
  "JAPN",
  "JOUR",
  "KINE",
  "KNFB",
  "LAND",
  "LBAR",
  "LDEV",
  "LING",
  "LMAS",
  "MARA",
  "MARB",
  "MARE",
  "MARR",
  "MARS",
  "MART",
  "MASC",
  "MASE",
  "MAST",
  "MATH",
  "MEEN",
  "MEFB",
  "MEPS",
  "METR",
  "MGMT",
  "MICR",
  "MKTG",
  "MLSC",
  "MMET",
  "MODL",
  "MSEN",
  "MTDE",
  "MUSC",
  "MUST",
  "MXET",
  "NAUT",
  "NRSC",
  "NUEN",
  "NURS",
  "NUTR",
  "NVSC",
  "OCEN",
  "OCNG",
  "PBSI",
  "PERF",
  "PETE",
  "PHIL",
  "PHLT",
  "PHYS",
  "PLPA",
  "POLS",
  "PORT",
  "POSC",
  "RDNG",
  "RELS",
  "RENR",
  "RPTS",
  "RUSS",
  "RWFM",
  "SCEN",
  "SCMT",
  "SCSC",
  "SEFB",
  "SENG",
  "SOCI",
  "SOMS",
  "SPAN",
  "SPED",
  "SPMT",
  "STAT",
  "TCMG",
  "TEED",
  "TEFB",
  "UGST",
  "URPN",
  "VIBS",
  "VIST",
  "VLCS",
  "VSCS",
  "VTPB",
  "VTPP",
  "WFSC",
  "WGST",
  "ZOOL",
];

const SellYourTextbooksCTA = ({ userDoc }: { userDoc: UserDoc | null }) => {
  return (
    <div>
      <h1 className="fw-bold mb-0" style={{ letterSpacing: "-1px" }}>
        Sell Your Textbooks to other Texas A&M students
      </h1>
      <p className="h4 mt-2">
        And get new books without paying absurd bookstore prices
      </p>

      <p className="text-muted h4 mt-3">
        Plaza is the student-to-student textbook marketplace for Texas A&M
        University.
      </p>

      <div className="d-flex flex-column align-items-stretch flex-md-row align-items-md-start mt-4 mb-3">
        {!userDoc && (
          <button
            className="btn btn-primary shadow-sm me-md-3 mb-2"
            onClick={signInWithGoogleHandler}
          >
            Join with Google
          </button>
        )}
        <Link className="btn btn-light border shadow-sm" to="/market">
          View Marketplace
        </Link>
      </div>
      {!userDoc && (
        <div>
          <p className="small text-muted">
            By creating an account, you agree to the{" "}
            <a
              className="text-decoration-none"
              href="https://app.termly.io/document/terms-of-use-for-website/1a006fda-20d0-4c95-9d94-a985a112c2bf"
            >
              Terms of Service
            </a>
            . For more information about our privacy practices, see the{" "}
            <a
              className="text-decoration-none"
              href="https://app.termly.io/document/privacy-policy/337f8197-7205-4366-896e-abca95bbe00b"
            >
              Plaza Privacy Policy
            </a>
            .
          </p>
        </div>
      )}
    </div>
  );
};

export const getViewportWidth = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

export function getFromBreakpoint<T>(
  viewportWidth: number,
  values?: {
    xxl?: T;
    xl?: T;
    lg?: T;
    md?: T;
    sm?: T;
    xs?: T;
  }
): { threshold: number; name: string; value: T | null } {
  const breakpoints = [
    {
      thresh: 1400,
      name: "xxl",
    },
    {
      thresh: 1200,
      name: "xl",
    },
    {
      thresh: 992,
      name: "lg",
    },
    {
      thresh: 768,
      name: "md",
    },
    {
      thresh: 576,
      name: "sm",
    },
    {
      thresh: 0,
      name: "xs",
    },
  ];

  for (let i = 0; i < breakpoints.length; i++) {
    const pt = breakpoints[i];
    if (viewportWidth >= pt.thresh) {
      const nameKey = pt.name as keyof typeof values;
      return {
        threshold: pt.thresh,
        name: pt.name,
        value: values?.[nameKey] ?? null,
      };
    }
  }

  return {
    threshold: 0,
    name: "xs",
    value: values?.xs ?? null,
  };
}

const getBootstrapContainerMaxWidth = (viewportWidth: number) => {
  const { value } = getFromBreakpoint<number>(viewportWidth, {
    xxl: 1320,
    xl: 1140,
    lg: 960,
    md: 720,
    sm: 540,
    xs: viewportWidth,
  });

  console.log(value);

  return value as number;
};

export interface MaxWidthProps {
  className: string;
  style: {
    maxWidth: string;
  };
}

const RootPage = () => {
  React.useEffect(() => {
    document.title = "Plaza Texas A&M";
  }, []);

  const vw = getViewportWidth();

  const isLargeViewpointWidth = vw >= 768;
  const marketStyleObj = isLargeViewpointWidth
    ? { height: "100vh", overflow: "scroll" }
    : {};

  const containerMaxWidth = getBootstrapContainerMaxWidth(vw);
  console.log(vw, containerMaxWidth);

  const getMaxWidthProps = (isLeftSide: boolean): MaxWidthProps =>
    isLargeViewpointWidth
      ? {
          className: isLeftSide ? "ms-auto" : "me-auto",
          style: { maxWidth: `${containerMaxWidth / 2}px` },
        }
      : ({} as MaxWidthProps);

  return (
    <UserContext.Consumer>
      {(context) => (
        <div>
          <LandingNavbar />
          <div className="container-fluid">
            <div className="row">
              <div
                className={`col-12 col-md-6 ${
                  isLargeViewpointWidth ? "bg-light" : ""
                }`}
                // style={
                //   isLargeViewpointWidth
                //     ? { backgroundColor: "rgb(251, 252, 253)" }
                //     : {}
                // }
              >
                <div
                  className="d-flex flex-column justify-content-between px-md-3"
                  style={isLargeViewpointWidth ? { height: "100vh" } : {}}
                >
                  <div {...getMaxWidthProps(true)}>
                    <div style={{ height: "120px" }}></div>
                    <SellYourTextbooksCTA userDoc={context.userDoc} />
                    <div className="mt-4">
                      <GiftCardGiveawayCard />
                    </div>
                  </div>

                  <div
                    className={
                      getMaxWidthProps(true).className + " d-none d-md-block"
                    }
                    style={
                      getMaxWidthProps(true)?.style?.maxWidth
                        ? {
                            width: getMaxWidthProps(true).style.maxWidth,
                          }
                        : {}
                    }
                  >
                    <p
                      className="small text-center text-muted"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      © 2022 Blue Desk Labs, LLC •{" "}
                      <a
                        href="email:support@getplaza.app"
                        className="text-decoration-none text-muted"
                      >
                        support@getplaza.app
                      </a>{" "}
                      •{" "}
                      <a
                        href="https://app.termly.io/document/terms-of-use-for-website/1a006fda-20d0-4c95-9d94-a985a112c2bf"
                        className="text-decoration-none text-muted"
                      >
                        Terms of Service
                      </a>{" "}
                      •{" "}
                      <a
                        href="https://app.termly.io/document/privacy-policy/337f8197-7205-4366-896e-abca95bbe00b"
                        className="text-decoration-none text-muted"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div style={marketStyleObj}>
                  <div className="px-md-3 pt-4 pt-md-0">
                    <div {...getMaxWidthProps(false)}>
                      <div
                        style={{ height: "120px" }}
                        className="d-none d-md-block"
                      ></div>
                      <p className="fw-bold h3 mb-2">Textbooks</p>
                      <TextbooksMarket
                        schoolID="4tH0YeST8WR622vkxMP0"
                        // maxWidthProps={getMaxWidthProps(false)}
                      />

                      <p
                        className="small text-center text-muted d-block d-md-none"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        © 2022 Blue Desk Labs, LLC •{" "}
                        <a
                          href="email:support@getplaza.app"
                          className="text-decoration-none text-muted"
                        >
                          support@getplaza.app
                        </a>{" "}
                        •{" "}
                        <a
                          href="https://app.termly.io/document/terms-of-use-for-website/1a006fda-20d0-4c95-9d94-a985a112c2bf"
                          className="text-decoration-none text-muted"
                        >
                          Terms of Service
                        </a>{" "}
                        •{" "}
                        <a
                          href="https://app.termly.io/document/privacy-policy/337f8197-7205-4366-896e-abca95bbe00b"
                          className="text-decoration-none text-muted"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default RootPage;
