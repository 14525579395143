import React from "react";

const DataRequestPage = () => {
  return (
    <div className="container">
      <div className="mt-5">
        <h4>Plaza Data Request</h4>
        <a href="https://app.termly.io/notify/337f8197-7205-4366-896e-abca95bbe00b">
          Access the Data Subject Access Request Form
        </a>
      </div>
    </div>
  );
};

export default DataRequestPage;
