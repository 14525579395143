import React from "react";
import Navbar, { MobileBottomTabBar } from "../../components/Navbar";
import {
  Link,
  NavigateFunction,
  useNavigate,
  redirect,
} from "react-router-dom";

import { app } from "../../firebase";

import { getAuth, signOut } from "firebase/auth";
import { UserContext } from "../../UserState";

import EditIcon from "@mui/icons-material/Edit";
import { NeedToSignIn } from "../home/HomePage";

export const profilePageLoader = async () => {
  // const uid = getAuth(app).currentUser?.uid;
  // if (!uid) return redirect("/");
};

export const signOutUser = (navigate: NavigateFunction) => {
  console.log("User Signing Out");
  const auth = getAuth(app);
  signOut(auth);
  console.log("User Signed Out");
  navigate("/");
};

const ProfilePage = () => {
  const navigate = useNavigate();
  const { userDoc } = React.useContext(UserContext);

  const onClickSignOut = () => {
    signOutUser(navigate);
  };

  return (
    <div>
      <Navbar />
      <div className="fixed-bottom border-top">
        <MobileBottomTabBar />
      </div>

      {userDoc ? (
        <div className="container mt-3">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-3">Account Info</h4>
              <div className="mb-3">
                <p className="fw-bold mb-0">Name</p>
                <p className="mb-0">{userDoc.name}</p>
              </div>
              <div className="mb-3">
                <p className="fw-bold mb-0">Email</p>
                <p className="mb-0">{userDoc.email}</p>
              </div>
              <div className="mb-3">
                <p className="fw-bold mb-0">Your School</p>
                <p className="mb-0">Texas A&M University</p>
              </div>
              <div className="mb-3">
                <p className="fw-bold mb-0">Joined Plaza</p>
                <p className="mb-0">{userDoc.joinedDate.toDateString()}</p>
              </div>
              <div>
                <p className="mb-0 text-muted">Only you can see this page</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row mt-4">
            <button className="btn btn-dark flex-fill" onClick={onClickSignOut}>
              Sign Out
            </button>
          </div>
        </div>
      ) : (
        <NeedToSignIn />
      )}
    </div>
  );
};

export default ProfilePage;
