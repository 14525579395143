import * as React from "react";

import { app, db } from "./firebase";

import { getAuth, User, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  Timestamp,
  setDoc,
} from "firebase/firestore";
import {
  UserDoc,
  UserDocSchoolTermData,
  CourseDocData,
  CourseDoc,
  SchoolTermDocData,
  MarketItem,
} from "./models";

export interface UserData {
  signedIn: boolean;
  authUser: User | null;
  userDoc: UserDoc | null;
  setUserDoc: (getNewUserDoc: (curr: UserDoc) => UserDoc) => void;
  addUserCourse: (termID: string, courseID: string) => Promise<void> | null;
  deleteUserCourse: (termID: string, courseID: string) => Promise<void> | null;
}

const DEFAULT_APP_DATA: UserData = {
  signedIn: false,
  authUser: null,
  userDoc: null,
  setUserDoc: (getNewUserDoc) => {},
  addUserCourse: async (termID, courseID) => {},
  deleteUserCourse: async (termID, courseID) => {},
};

export const UserContext = React.createContext<UserData>(DEFAULT_APP_DATA);

const getUserDoc = async (uid: string): Promise<UserDoc | null> => {
  const userDoc = doc(db, `users/${uid}`);
  const snap = await getDoc(userDoc);

  if (!snap.exists()) return null;

  const docData = snap.data() as UserDoc;

  const cleanedDocData = {
    ...docData,
    terms: orderSchoolTerms(docData.terms) as UserDocSchoolTermData[],
    joinedDate: (docData.joinedDate as any as Timestamp).toDate(),
  };

  return cleanedDocData;
};

export const orderSchoolTerms = (terms: SchoolTermDocData[]) => {
  return terms.sort((a, b) => {
    return a.order - b.order;
  });
};

const UserState: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [userData, setUserData] = React.useState<UserData>(DEFAULT_APP_DATA);

  const auth = getAuth(app);

  React.useEffect(() => {
    console.log("Set Up UserState UseEffect");
    onAuthStateChanged(auth, async (user) => {
      console.log("Auth State Changed");
      if (user) {
        console.log("User Signed In");
        let userDoc = await getUserDoc(user.uid);

        //user exists and is signed in, the user doc now needs to be created
        if (userDoc === null) {
          const docRef = doc(db, `/users/${user.uid}`);

          const nowDate = new Date();
          const trackingID = localStorage.getItem("visitor") ?? null;
          const newUserDoc: UserDoc = {
            currentTermID: "0B0exMn82oo4uIcWdcy3",
            UID: user.uid,
            email: user.email as string,
            joinedDate: nowDate,
            marketItems: [] as MarketItem[],
            schoolEmailAddress: null,
            name: user.displayName as string,
            schoolID: "4tH0YeST8WR622vkxMP0",
            terms: [
              {
                termName: "Fall 2022",
                termID: "0B0exMn82oo4uIcWdcy3",
                termData: {
                  code: "202231",
                  description: "Fall 2022 - College Station",
                },
                order: 0,
                courses: [],
              },
              {
                termName: "Spring 2023",
                termID: "z9Y2f3XXpFDSXEdgsMRt",
                termData: {
                  code: "202311",
                  description: "Spring 2023 - College Station",
                },
                order: 1,
                courses: [],
              },
            ],
          };

          try {
            await setDoc(docRef, {
              ...newUserDoc,
              trackedVisitorID: trackingID,
            });
            userDoc = newUserDoc;
          } catch (e) {
            console.log(e);
          }
        }

        setUserData({
          signedIn: !!userDoc,
          authUser: user,
          userDoc: userDoc,
          setUserDoc: (getNewUserDoc) => {
            setUserData((st) => {
              return {
                ...st,
                userDoc: getNewUserDoc(st.userDoc ?? ({} as UserDoc)),
              };
            });
          },
          addUserCourse: async (termID, courseID) => {
            if (!userDoc) return;
            const userDocRef = doc(db, `/users/${userDoc.UID}`);
            try {
              //update firestore
              const resultedTermsArray = await runTransaction(
                db,
                async (transaction) => {
                  const fetchedUserDoc = await transaction.get(userDocRef);
                  if (!fetchedUserDoc.exists()) {
                    throw "User Doc does not exist!";
                  }

                  const fetchedData = fetchedUserDoc.data() as UserDoc;

                  const changedTermMap = fetchedData.terms.filter(
                    (t) => t.termID === termID
                  )[0];

                  const addedCourseDocRef = doc(
                    db,
                    `/schools/${fetchedData.schoolID}/terms/${termID}/courses/${courseID}`
                  );
                  const addedCourseDoc = await transaction.get(
                    addedCourseDocRef
                  );

                  const addedCourseDocData =
                    addedCourseDoc.data() as CourseDocData;

                  const addedCourse: CourseDoc = {
                    ...addedCourseDocData,
                    ID: courseID,
                  };

                  const newCoursesArray = [
                    ...changedTermMap.courses,
                    addedCourse,
                  ];

                  const newTerm = {
                    ...changedTermMap,
                    courses: newCoursesArray,
                  };

                  const otherTerms = fetchedData.terms.filter(
                    (t) => t.termID !== termID
                  );

                  const newTermsArray = [...otherTerms, newTerm];
                  await transaction.update(userDocRef, {
                    terms: newTermsArray,
                  });

                  return newTermsArray;
                }
              );
              //set local data to firestore data
              setUserData((st) => {
                const newSt = {
                  ...st,
                  userDoc: {
                    ...st.userDoc,
                    terms: orderSchoolTerms(resultedTermsArray),
                  },
                } as UserData;

                return newSt;
              });
            } catch (err) {
              console.log("Transaction failed: ", err);
            }
          },
          deleteUserCourse: async (termID, courseID) => {
            if (!userDoc) return;
            const userDocRef = doc(db, `/users/${userDoc.UID}`);
            try {
              //update firestore
              const resultedTermsArray = await runTransaction(
                db,
                async (transaction) => {
                  const fetchedUserDoc = await transaction.get(userDocRef);
                  if (!fetchedUserDoc.exists()) {
                    throw "User Doc does not exist!";
                  }

                  const fetchedData = fetchedUserDoc.data() as UserDoc;

                  const changedTermMap = fetchedData.terms.filter(
                    (t) => t.termID === termID
                  )[0];

                  const newCoursesArray = changedTermMap.courses.filter(
                    (c) => c.ID !== courseID
                  );

                  const newTerm = {
                    ...changedTermMap,
                    courses: newCoursesArray,
                  };

                  const otherTerms = fetchedData.terms.filter(
                    (t) => t.termID !== termID
                  );

                  const newTermsArray = [...otherTerms, newTerm];
                  await transaction.update(userDocRef, {
                    terms: newTermsArray,
                  });

                  return newTermsArray;
                }
              );
              //set local data to firestore data
              setUserData((st) => {
                const newSt = {
                  ...st,
                  userDoc: {
                    ...st.userDoc,
                    terms: orderSchoolTerms(resultedTermsArray),
                  },
                } as UserData;

                return newSt;
              });
            } catch (err) {
              console.log("Transaction failed: ", err);
            }
          },
        });
      } else {
        console.log("User Signed Out");
        setUserData(DEFAULT_APP_DATA);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  );
};

export default UserState;
