import React from "react";

import { getAuth } from "firebase/auth";
import {
  Link,
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { app, db } from "../../firebase";

import Navbar, {
  BackBanner,
  MobileBottomTabBar,
  signInWithGoogleHandler,
} from "../../components/Navbar";
import { UserContext } from "../../UserState";
import { AppContext } from "../../AppState";

import {
  BookCondition,
  BOOK_CONDITIONS,
  CourseSectionTextbookIDsMap,
  formatBookAuthor,
  MarketItem,
  TextbookData,
  TextbookMarketItem,
  TextbookMarketItemCreationData,
} from "../../models";
import {
  getDurationText,
  GiftCardGiveawayCard,
  NeedToSignIn,
} from "../home/HomePage";

import ClearIcon from "@mui/icons-material/Clear";
import { shareTextbookPostLink } from "./MarketItemPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const marketItemAddPageLoader = async () => {
  // const uid = getAuth(app).currentUser?.uid;
  // if (!uid) return redirect("/");
};

interface CourseMatchedTextbook extends TextbookData {
  courseSections: CourseSectionTextbookIDsMap[];
  courses: {
    [courseName: string]: number;
  };
}

const getSuggestedPrice = (
  book: TextbookData,
  condition: BookCondition
): { price: number; message: string } => {
  const { itemPrices } = book;
  const print = itemPrices[0].prices;
  const newPrint = print[0].price;

  let price;
  let message;

  if (condition === "VERY_GOOD") {
    price = newPrint * 0.6;
    message = "60% of the New Print bookstore price";
  } else if (condition === "GOOD") {
    if (print?.[1]?.price) {
      price = print?.[1]?.price * 0.6;
      message = "60% of the Used Print bookstore price";
    } else {
      price = newPrint * 0.4;
      message = "40% of the New Print bookstore price";
    }
  } else {
    if (print?.[1]?.price) {
      price = print?.[1]?.price * 0.4;
      message = "40% of the Used Print bookstore price";
    } else {
      price = newPrint * 0.25;
      message = "25% of the New Print bookstore price";
    }
  }

  return { price: Math.floor(price), message: message };
};

const postTextbookFormValidator = (
  textbookID: string,
  condition: BookCondition | "",
  price: string
): { validForSubmit: boolean; message: string } => {
  if (!textbookID) {
    return {
      validForSubmit: false,
      message:
        "Please use the search feature to find your textbook before posting.",
    };
  } else if (!condition || condition.length === 0) {
    return {
      validForSubmit: false,
      message: "Please select what condition your textbook is in.",
    };
  } else if (!price || isNaN(parseFloat(price)) || parseFloat(price) < 0.0) {
    return {
      validForSubmit: false,
      message: "Please select a price for your textbook listing.",
    };
  } else {
    return {
      validForSubmit: true,
      message: "All good to submit",
    };
  }
};

const MarketItemAddPage = () => {
  const userContext = React.useContext(UserContext);
  const {
    termTextbooksCache,
    termCourseSectionTextbooksCache,
    loadTermTextbooksCache,
    loadTermCourseSectionTextbooksCache,
    termTextbooksCacheLoaded,
    termCourseSectionTextbooksCacheLoaded,
    getTermCourseSectionTextbooksCacheForTerm,
    getTermTextbooksCacheForTerm,
    loadUserMarketItems,
    postMarketItem,
    updateMarketItem,
    deleteMarketItem,
    marketItems,
  } = React.useContext(AppContext);

  const tamuID = "4tH0YeST8WR622vkxMP0";
  const spring2023TermID = "z9Y2f3XXpFDSXEdgsMRt";

  const textbooksCacheLoaded = termTextbooksCacheLoaded(
    termTextbooksCache[tamuID],
    spring2023TermID
  );

  const courseSectionTextbooksCacheLoaded =
    termCourseSectionTextbooksCacheLoaded(
      termCourseSectionTextbooksCache[tamuID],
      spring2023TermID
    );

  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  let editItem: TextbookMarketItem | null = null;
  let editBook: TextbookData | null = null;

  let loadingEditItemDefaultState = false;
  if (urlParams.marketItemID) {
    const items = (marketItems[tamuID] ?? []).filter(
      (item) => item.marketItemID === urlParams.marketItemID
    );
    if (items.length > 0) {
      editItem = items[0] as TextbookMarketItem;
      editBook = (items[0] as TextbookMarketItem).textbookData;
    } else {
      loadingEditItemDefaultState = true;
    }
  }

  const getDefaultPriceCalcText = (
    item: TextbookMarketItem | null,
    book: TextbookData | null
  ) => {
    if (!item || !book) return "";
    const defText = getSuggestedPrice(book, item.bookCondition);
    if (item.price === defText.price) return defText.message;
    else return "";
  };

  const getDefaults = (
    item: TextbookMarketItem | null,
    book: TextbookData | null
  ) => {
    return {
      selectedTextbookID: book ? book.textbookID : "",
      priceInput: item ? item.price.toFixed(2) : "",
      selectedCondition: (item ? item.bookCondition : "") as BookCondition | "",
      description: item ? item.description : "",
      priceCalcText: getDefaultPriceCalcText(item, book),
    };
  };

  const defaults = getDefaults(editItem, editBook);

  const [loadingEditItem, setLoadingEditItem] = React.useState(
    loadingEditItemDefaultState
  );

  React.useEffect(() => {
    console.log("ran effecttt");
    if (loadingEditItem && userContext?.userDoc) {
      const { schoolID, UID } = userContext.userDoc;
      loadUserMarketItems(schoolID, UID).then(() => {
        setLoadingEditItem(false);
      });
    }
  }, [userContext.userDoc]);

  React.useEffect(() => {
    if (editItem && editBook) {
      console.log("resetting default user inputs", editItem);
      const newDefs = getDefaults(editItem, editBook);
      setSelectedTextbookID(newDefs.selectedTextbookID);
      setSelectedCondition(newDefs.selectedCondition);
      setPriceInput(newDefs.priceInput);
      setPriceCalcText(newDefs.priceCalcText);
      document.title = "Edit Textbook - Plaza";
    }
  }, [editItem]);

  React.useEffect(() => {
    document.title = "Post Textbook - Plaza";
  }, []);

  const [isQuickAdd, setIsQuickAdd] = React.useState(true);

  const [bookTitle, setBookTitle] = React.useState("");
  const [bookAuthor, setBookAuthor] = React.useState("");
  const [bookCourse, setBookCourse] = React.useState("");

  const [bookSearchInput, setBookSearchInput] = React.useState("");

  const [selectedTextbookID, setSelectedTextbookID] = React.useState(
    defaults.selectedTextbookID
  );
  const [selectedCondition, setSelectedCondition] = React.useState<
    BookCondition | ""
  >(defaults.selectedCondition);
  const [priceInput, setPriceInput] = React.useState(defaults.priceInput);

  const [priceCalcText, setPriceCalcText] = React.useState(
    defaults.priceCalcText
  );

  const descriptionRef = React.useRef(null);

  const [descriptionInputRows, setDescriptionInputRows] = React.useState(2);
  const incrementDescriptionInputRows = () =>
    setDescriptionInputRows((st) => st + 2);
  const decrementDescriptionInputRows = () =>
    setDescriptionInputRows((st) => (st > 2 ? st - 2 : st));

  const [errorMessage, setErrorMessage] = React.useState("");

  const [isPosting, setIsPosting] = React.useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const [textbookPosted, setTextbookPosted] =
    React.useState<TextbookMarketItem | null>(null);

  const deleteHandler = async () => {
    if (editItem && userContext?.userDoc?.UID && !isPosting) {
      setIsPosting(true);

      const { userMarketItems, success, message } = await deleteMarketItem(
        userContext.userDoc.schoolID,
        userContext.userDoc.UID,
        editItem.marketItemID
      );
      if (!success) {
        console.log("error message", message);
        alert(
          "Error Occurred in deleting the textbook." +
            " Make sure your device is connected to the internet."
        );
      }

      userContext.setUserDoc((st) => {
        return {
          ...st,
          marketItems: userMarketItems,
        };
      });
      setIsPosting(false);

      navigate("/home", { replace: true });
    }
  };

  const postTextbookHandler = async () => {
    const { validForSubmit, message } = postTextbookFormValidator(
      selectedTextbookID,
      selectedCondition,
      priceInput
    );

    console.log("valid for submit", validForSubmit);

    if (
      userContext?.userDoc?.UID &&
      validForSubmit &&
      !isPosting &&
      descriptionRef?.current !== null
    ) {
      const newItem: TextbookMarketItemCreationData = {
        textbookID: selectedTextbookID,
        bookCondition: selectedCondition as BookCondition,
        itemType: "textbooks",
        price: Number(priceInput),
        schoolID: tamuID,
        sellerUID: userContext.userDoc?.UID ?? "",
        sellerName: userContext.userDoc?.name ?? "",
        textbookData: getTextbookDataByID(selectedTextbookID),
        textbookInfo: null,
        imageURLs: [],
        description: (descriptionRef?.current as any)?.value ?? "",
      };
      setIsPosting(true);

      let transactionSuccess: boolean;
      let transactedItem: MarketItem;
      let newUserMarketItems: MarketItem[];

      if (editItem) {
        const params = await updateMarketItem(tamuID, userContext.userDoc.UID, {
          ...newItem,
          marketItemID: editItem.marketItemID,
        } as TextbookMarketItem);
        transactionSuccess = params.success;
        transactedItem = params.updatedMarketItem;
        newUserMarketItems = params.userMarketItems;
        console.log("params", params);
      } else {
        const params = await postMarketItem(
          tamuID,
          userContext.userDoc.UID,
          newItem
        );
        transactionSuccess = params.success;
        transactedItem = params.newMarketItem;
        newUserMarketItems = params.userMarketItems;
      }

      if (!transactionSuccess) {
        alert(
          "Error Occurred in form submission." +
            " Make sure your device is connected to the internet."
        );
      }

      /*
        TODO
        DONE create a new function in AppState to update the textbook listing
        DONE run a transaction so that you update the textbook on the market and on the user's doc
        
        if editItem exists then run the updateMarketItem function
        if editItem doesn't exist then run the postMarketItem function

        render the posting success card with an specific message for after editing successfully

        change BackBanner to simply go back on the browser's history and accept text as props for the label

        add a views counter to the MarketItem model and keep track via the firestore doc
        count views as number of signed in users who have viewed the item
      */

      userContext.setUserDoc((st) => {
        return {
          ...st,
          marketItems: newUserMarketItems,
        };
      });
      setIsPosting(false);

      //clear the form
      setSelectedTextbookID("");
      setSelectedCondition("");
      setPriceInput("");
      setPriceCalcText("");

      //render a card that says success with a link to the new textbook item
      setTextbookPosted(transactedItem as TextbookMarketItem);
    } else {
      setErrorMessage(message);
    }
  };

  const allLoaded = textbooksCacheLoaded && courseSectionTextbooksCacheLoaded;

  React.useEffect(() => {
    if (!allLoaded) {
      loadTermCourseSectionTextbooksCache(tamuID, spring2023TermID);
      loadTermTextbooksCache(tamuID, spring2023TermID);
    }
  }, [userContext.userDoc]);

  if (!userContext.userDoc) {
    //TODO log this event with analytics, would be good stat to have on
    //how many visits to the homepage without signing in we have
    return (
      <div>
        <Navbar />
        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>
        <NeedToSignIn />
      </div>
    );
  }

  if (!allLoaded || loadingEditItem) {
    return (
      <div>
        <Navbar overrideTitle="Post Textbook" />
        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>

        {location.state?.showBack && (
          <div className="py-3 d-none d-sm-block">
            <div className="container">
              <div
                className="d-flex flex-row align-items-center"
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              >
                <ArrowBackIcon />

                <p className="h6 ms-3 mb-0">Go Back</p>
              </div>
            </div>
          </div>
        )}
        <div className="container mt-3">
          <GiftCardGiveawayCard />
        </div>

        <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
          <div>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showDeleteConfirm) {
    return (
      <div>
        <Navbar overrideTitle="Post Textbook" />
        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>
        <div className="container mt-3">
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">
              Deleting is an irreversible operation
            </h4>
            <p className="mb-0">
              After you delete, we cannot get your textbook post back. However,
              If you decide you want to create a new post to list this textbook
              again later, you can do so.
            </p>
          </div>

          <h4 className="card-title fw-bold mt-5 mb-3">
            Are you sure you want to delete?
          </h4>

          <div className="d-flex flex-column flex-md-row">
            <button
              className="btn btn-primary mb-4 mb-md-0 me-md-3"
              onClick={() => setShowDeleteConfirm(false)}
            >
              Don't Delete! Go Back to Update Page
            </button>
            <button className="btn btn-outline-danger" onClick={deleteHandler}>
              Delete Textbook For Sure
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (textbookPosted) {
    const verb = editItem ? "Updated" : "Posted";
    const adjective = editItem ? "Updated" : "New";
    return (
      <div>
        <Navbar overrideTitle="Post Textbook" />
        <div className="fixed-bottom border-top">
          <MobileBottomTabBar />
        </div>
        <div className="container mt-3">
          <div className="card border-0">
            <div className="card-body">
              <h4 className="card-title fw-bold mb-4">
                Textbook {verb} Successfully!
              </h4>
              <div className="d-flex flex-column flex-md-row mb-2">
                <Link
                  className="btn btn-primary me-md-2 mb-3 mb-md-0 text-decoration-none"
                  to={`/market/${textbookPosted.marketItemID}`}
                  replace
                  state={{ showBack: true }}
                >
                  View Your {adjective} Textbook Listing
                </Link>
                <button
                  className="btn btn-outline-primary d-md-none"
                  onClick={() =>
                    shareTextbookPostLink(textbookPosted.marketItemID)
                  }
                >
                  Share Your {adjective} Textbook Listing
                </button>
              </div>
              <p>
                Post your textbook listing on your Instagram story, and tag us{" "}
                <span className="fw-bold">@plazaplatform</span> on Instagram,
                Tiktok, Youtube, or Twitter!
              </p>
              <div>
                <a
                  className="btn btn-light border me-2"
                  href="https://www.instagram.com/plazaplatform/"
                >
                  Instagram
                </a>
                <a
                  className="btn btn-dark me-2"
                  href="https://www.tiktok.com/@plazaplatform"
                >
                  Tiktok
                </a>
                <a
                  className="btn btn-danger me-2"
                  href="https://m.youtube.com/@plazaplatform"
                >
                  Youtube
                </a>
                <a
                  className="btn btn-primary me-2"
                  href="https://twitter.com/plazaplatform"
                >
                  Twitter
                </a>
                {/* <div className="btn btn-warning">Snapchat: @plazaplatform</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const currTermCSTextbooks = getTermCourseSectionTextbooksCacheForTerm(
    termCourseSectionTextbooksCache[tamuID],
    spring2023TermID
  );
  const currTermTextbooks = getTermTextbooksCacheForTerm(
    termTextbooksCache[tamuID],
    spring2023TermID
  );
  if (currTermCSTextbooks === null || currTermTextbooks === null) {
    throw "TextbooksPage, term has not been loaded yet";
  }

  let textbooksList: CourseMatchedTextbook[] = [];

  currTermTextbooks.textbooks.forEach((textbookData) => {
    const { textbookID } = textbookData;
    const courseSections = currTermCSTextbooks.textbooks.filter((cs) => {
      return cs.textbookIDs.includes(textbookID);
    });
    let courses: {
      [courseName: string]: number;
    } = {};

    courseSections.forEach((cs) => {
      const numSections = courseSections.filter(
        (s) => s.courseName === cs.courseName
      );
      courses[cs.courseName] = numSections.length;
    });
    textbooksList.push({
      ...textbookData,
      courseSections: courseSections,
      courses: courses,
    });
  });

  const getTextbookDataByID = (bkID: string): TextbookData =>
    currTermTextbooks.textbooks.filter((bk) => bk.textbookID === bkID)[0];

  const getTextbookByID = (bkID: string) =>
    textbooksList.filter((bk) => bk.textbookID === bkID)[0];

  let selectedTextbook: CourseMatchedTextbook | null = null;
  if (selectedTextbookID.length > 0) {
    selectedTextbook = getTextbookByID(selectedTextbookID);
  }

  let shownTextbooks: CourseMatchedTextbook[] = [];
  if (bookSearchInput.length > 0) {
    shownTextbooks = textbooksList.filter((book) => {
      const upInput = bookSearchInput.toUpperCase();
      const { ISBN, itemTitle, itemAuthor, courseSections } = book;
      const isbnMatch = ISBN.toUpperCase().includes(upInput);
      const titleMatch = itemTitle.toUpperCase().includes(upInput);
      const authorMatch = itemAuthor.toUpperCase().includes(upInput);
      const coursesMatched =
        courseSections.filter((c) =>
          c.courseName.toUpperCase().includes(upInput)
        ).length > 0;
      return isbnMatch || titleMatch || authorMatch || coursesMatched;
    });
  }

  return (
    <div>
      <Navbar
        overrideTitle={editItem ? "Edit Textbook Post" : "Post Textbook"}
        marketPostTextbookButtonClick={postTextbookHandler}
        isPostingMarketItem={isPosting}
      />
      <div className="fixed-bottom border-top">
        <MobileBottomTabBar />
      </div>

      {location.state?.showBack && (
        <div className="py-3 d-none d-sm-block">
          <div className="container">
            <div
              className="d-flex flex-row align-items-center"
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              <ArrowBackIcon />

              <p className="h6 ms-3 mb-0">Go Back</p>
            </div>
          </div>
        </div>
      )}

      {!editItem && (
        <div className="container my-3">
          <GiftCardGiveawayCard />
        </div>
      )}

      {errorMessage > "" && (
        <div className="container my-3">
          <div className="alert alert-danger">
            <h4 className="card-title">Error Posting Textbook</h4>
            <p className="mb-0">{errorMessage}</p>
          </div>
        </div>
      )}

      <div className="container mt-2" style={{ marginBottom: "80px" }}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-none d-sm-block">
            {editItem ? (
              <h2 className="fw-bold mb-0">Edit Textbook Post</h2>
            ) : (
              <React.Fragment>
                <h2 className="fw-bold mb-0">Post Your Textbook</h2>
                <p className="text-muted mb-0">for Spring 2023</p>
              </React.Fragment>
            )}
          </div>
        </div>
        {editItem && (
          <p className="mt-2 mb-0 text-muted h6">
            Posted {getDurationText(new Date(), editItem.postedDate, true)} ago
          </p>
        )}

        {!editItem && (
          <ul className="nav nav-pills my-3">
            <li
              className="nav-item"
              role="button"
              onClick={() => setIsQuickAdd(true)}
            >
              <span className={`nav-link ${isQuickAdd ? "active" : ""} me-2`}>
                <span>Quick Add</span>
              </span>
            </li>
            <li
              className="nav-item"
              role="button"
              onClick={() => setIsQuickAdd(false)}
            >
              <span className={`nav-link ${isQuickAdd ? "" : "active"} me-2`}>
                <span>Enter Details</span>
              </span>
            </li>
          </ul>
        )}

        {isQuickAdd ? (
          <React.Fragment>
            <div className="my-3">
              <div>
                <label htmlFor="quickAddInput" className="h5 mb-2">
                  Pick your textbook from the list
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter by book title, author, course, or ISBN"
                  id="quickAddInput"
                  value={bookSearchInput}
                  onChange={(e) => setBookSearchInput(e.target.value)}
                />
                <small className="text-muted">
                  Search all {textbooksList.length} textbooks used at Texas A&M
                  University
                </small>
              </div>
              {bookSearchInput.length > 0 && (
                <div className="mt-2 border rounded shadow-sm">
                  {shownTextbooks.slice(0, 20).map((book, i) => {
                    return (
                      <div
                        className={`p-2 ${
                          i === shownTextbooks.slice(0, 20).length - 1
                            ? ""
                            : "border-bottom"
                        }`}
                        key={book.textbookID}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedTextbookID(book.textbookID);
                          setBookSearchInput("");

                          if (selectedCondition) {
                            const { price, message } = getSuggestedPrice(
                              book,
                              selectedCondition
                            );
                            setPriceInput(price.toFixed(2));
                            setPriceCalcText(message);
                          }
                        }}
                      >
                        <p className="mb-0">{book.itemTitle}</p>
                        <p className="text-muted mb-0">
                          {formatBookAuthor(book.itemAuthor)}
                        </p>
                        <small className="text-muted mb-0">
                          ISBN-13: {book.ISBN}
                        </small>
                        <div
                          className="d-flex flex-row hide-scrollbar mt-2"
                          style={{ overflow: "scroll" }}
                        >
                          {Object.keys(book.courses).map((name) => {
                            return (
                              <div
                                className="d-flex flex-row justify-content-start me-2 border rounded"
                                style={{ whiteSpace: "nowrap" }}
                                key={name}
                              >
                                <div className="me-2 ps-2 py-2">{name}</div>
                                <div className="bg-light px-2 py-2 border-start">
                                  {book.courses[name]}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div className="d-flex flex-row mt-2">
                          {book.itemPrices.map((priceType) => {
                            return (
                              <div
                                className={`d-flex flex-column me-3`}
                                key={priceType.type}
                              >
                                <div className="d-flex flex-column">
                                  {priceType.prices.map((price, j) => {
                                    const isLast =
                                      priceType.prices.length - 1 === j;
                                    return (
                                      <div
                                        className={`d-flex flex-row me-2 ${
                                          isLast ? "" : "mb-2"
                                        }`}
                                        key={price.subtype}
                                      >
                                        <span
                                          style={{ whiteSpace: "nowrap" }}
                                          className="me-2"
                                        >
                                          {price.subtype}
                                        </span>
                                        <span>${price.price.toFixed(2)}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {selectedTextbook && (
              <div className="mb-3">
                <div className="card">
                  <div className="card-header d-flex flex-row justify-content-between align-items-center">
                    <div>Selected Book</div>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedTextbookID("");
                      }}
                    >
                      <span>Clear</span>
                      <ClearIcon />
                    </div>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title mb-0">
                      {selectedTextbook.itemTitle}
                    </h4>
                    <p className="mb-0 mb-lg-2">
                      {formatBookAuthor(selectedTextbook.itemAuthor)}
                    </p>
                    <p className="text-muted">
                      ISBN-13: {selectedTextbook.ISBN}
                    </p>
                    <div
                      className="d-flex flex-row hide-scrollbar mt-2"
                      style={{ overflow: "scroll" }}
                    >
                      {Object.keys(selectedTextbook.courses).map((name) => {
                        if (!selectedTextbook?.courseSections)
                          return <span></span>;
                        return (
                          <div
                            className="d-flex flex-row justify-content-start me-2 border rounded"
                            style={{ whiteSpace: "nowrap" }}
                            key={name}
                          >
                            <div className="me-2 ps-2 py-2">{name}</div>
                            <div className="bg-light px-2 py-2 border-start">
                              Used in {selectedTextbook.courses[name]} sections
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="mb-4">
          <p className="h5">Add Textbook Images</p>
          <input
            className="form-control"
            type="file"
            id="imagesInput"
            onChange={(e) => console.log("file input", e.target.value)}
          />
        </div> */}

            <div className="mb-3 mb-sm-3">
              <p className="h5">Condition</p>
              <div>
                {BOOK_CONDITIONS.map((conditionObj) => {
                  const isActive = conditionObj.type === selectedCondition;
                  return (
                    <button
                      className={`p-2 me-2 mb-2 btn ${
                        isActive ? "btn-dark border-dark" : "btn-light border"
                      }`}
                      onClick={() => {
                        setSelectedCondition(conditionObj.type);
                        if (selectedTextbook) {
                          const { price, message } = getSuggestedPrice(
                            selectedTextbook,
                            conditionObj.type
                          );
                          setPriceInput(price.toFixed(2));
                          setPriceCalcText(message);
                        }
                      }}
                      key={conditionObj.type}
                    >
                      {conditionObj.name}
                    </button>
                  );
                })}
              </div>
              <div>
                {selectedCondition.length > 0 ? (
                  <span>
                    {
                      BOOK_CONDITIONS.filter(
                        (cond) => cond.type === selectedCondition
                      )[0].description
                    }
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label className="mb-2" htmlFor="priceInput">
                <div className="d-flex flex-column">
                  <span className="h5 mb-0">
                    Price{" "}
                    {selectedTextbook &&
                      selectedCondition.length > 0 &&
                      Number(priceInput) ===
                        getSuggestedPrice(
                          selectedTextbook,
                          selectedCondition as BookCondition
                        ).price && <span> - Suggested Price Autofilled</span>}
                  </span>
                  {selectedTextbook &&
                  selectedCondition.length > 0 &&
                  Number(priceInput) ===
                    getSuggestedPrice(
                      selectedTextbook,
                      selectedCondition as BookCondition
                    ).price ? (
                    <div>
                      <small className="text-muted d-block">
                        Suggested price is calculated as about{" "}
                        <span className="fw-bold">{priceCalcText}</span>.
                      </small>
                      <small className="text-muted d-block">
                        Feel free to change the price as you see fit.
                      </small>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </label>
              <div className="d-flex flex-column">
                <div className="input-group">
                  <span className="input-group-text">$</span>
                  <input
                    type="number"
                    className="form-control"
                    value={priceInput}
                    onChange={(e) => setPriceInput(e.target.value)}
                    step={1.0}
                    max={10000}
                    min={0.0}
                    id="priceInput"
                  />
                </div>
                {selectedTextbook !== null ? (
                  <div className="mt-2">
                    <div className="card">
                      <div className="card-header">
                        Price Comparison: TAMU Bookstore
                      </div>

                      <div className="d-flex flex-column flex-lg-row">
                        {selectedTextbook.itemPrices.map((priceType, i) => {
                          const isPrint = i === 0;
                          const isLast =
                            (selectedTextbook?.itemPrices?.length ?? 0) - 1 ===
                            i;

                          return (
                            <div
                              className={`d-flex flex-column card-body ${
                                isPrint ? "bg-light" : ""
                              }`}
                              key={priceType.type}
                            >
                              <p className="h6">{priceType.type}</p>
                              <div className="d-flex flex-column">
                                {priceType.prices.map((price, j) => {
                                  const isLast =
                                    priceType.prices.length - 1 === j;
                                  return (
                                    <div
                                      className={`d-flex flex-row me-2 ${
                                        isLast ? "" : "mb-2"
                                      }`}
                                      key={price.subtype}
                                    >
                                      <span
                                        style={{ whiteSpace: "nowrap" }}
                                        className="me-2"
                                      >
                                        {price.subtype}
                                      </span>
                                      <span>${price.price.toFixed(2)}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            </div>

            <div className="mb-3 mb-sm-4 d-flex flex-column align-items-stretch">
              <label htmlFor="descriptionInput">
                <p className="h5 mb-0">Description</p>
                <p className="pb-2 mb-0 small text-muted">Optional</p>
              </label>
              <textarea
                rows={descriptionInputRows}
                id="descriptionInput"
                className="border rounded p-2"
                ref={descriptionRef}
                defaultValue={defaults.description}
              ></textarea>
              <div className="d-flex flex-row justify-content-end">
                {descriptionInputRows > 2 && (
                  <button
                    className="btn btn-link text-decoration-none small text-dark"
                    onClick={decrementDescriptionInputRows}
                  >
                    Less Space
                  </button>
                )}

                <button
                  className="btn btn-link text-decoration-none small text-dark"
                  onClick={incrementDescriptionInputRows}
                >
                  More Space
                </button>
              </div>
            </div>

            <div className="mb-3 mb-sm-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">How Textbook Pickup Works</h4>
                  <p className="mb-0">
                    Your textbook will be available for other students to
                    purchase and message you to meet up for in-person pickup and
                    payment in early January 2023.
                  </p>
                  {/* <p className="mb-0">
                You will be able to cash out your earnings as soon as textbooks
                are available to be sold.
              </p> */}
                  {/* <p className="mb-0">
                Plaza will automatically connect your textbook listing with
                other students who need to purchase this textbook.
              </p> */}
                </div>
              </div>
            </div>

            <div className="d-flex flex-row mb-2">
              <button
                className="btn btn-primary flex-fill"
                onClick={postTextbookHandler}
                disabled={isPosting}
              >
                {editItem ? "Update Post" : "Post Textbook"}
              </button>
            </div>

            {editItem && (
              <div className="d-flex flex-row justify-content-center mt-4 mb-3">
                <button
                  className="btn btn-link text-danger text-decoration-none"
                  onClick={() => setShowDeleteConfirm(true)}
                  disabled={isPosting}
                >
                  Delete Post
                </button>
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <p className="text-muted">
                You'll be able to post a textbook that is not on our list, just
                by entering the details below from your memory{" "}
                <span className="fw-bold">
                  by 10pm Central Time December 15, 2022
                </span>
              </p>
              <p className="text-muted">
                You'll be notified via an email to your Plaza account email
                address when this feature becomes available
              </p>
              <div className="mb-3">
                <label htmlFor="titleInput">Textbook Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="titleInput"
                  placeholder="Ex. Introduction to Electrodynamics"
                  onChange={(e) => setBookTitle(e.target.value)}
                  value={bookTitle}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="authorInput">Textbook Author</label>
                <input
                  type="text"
                  className="form-control"
                  id="authorInput"
                  placeholder="Ex. Griffiths, David J."
                  onChange={(e) => setBookAuthor(e.target.value)}
                  value={bookAuthor}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="courseInput">Textbook Course</label>
                <input
                  type="text"
                  className="form-control"
                  id="courseInput"
                  placeholder="Ex. PHYS 305"
                  onChange={(e) => setBookCourse(e.target.value)}
                  value={bookCourse}
                  disabled
                />
              </div>
            </div>

            <div className="d-flex flex-row mb-2">
              <button className="btn btn-primary flex-fill" disabled>
                {editItem ? "Update Post" : "Post Textbook"}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default MarketItemAddPage;
