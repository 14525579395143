import { doc, getDoc } from "firebase/firestore";
import * as React from "react";

import { db } from "../../firebase";

import { AppContext, LoadingState, LoadingTermsState } from "../../AppState";
import {
  SchoolTermCacheTwoCourseData,
  SchoolTermCacheTwoDocData,
  UserDoc,
} from "../../models";
import { UserContext } from "../../UserState";

interface SetTermsTermInfo {
  termID: string;
  termName: string;
}

interface SetTermProps {
  currTermID: string;
  allTerms: SetTermsTermInfo[];
  setTermID: (id: string) => void;
}

const SetTerm = ({ currTermID, allTerms, setTermID }: SetTermProps) => {
  return (
    <ul className="nav nav-pills mb-2">
      {allTerms.map((term) => {
        const isActive = term.termID === currTermID ? "active" : "";

        const setTermIDHandler = () => {
          console.log("Set Term ID called", term.termID, term.termName);
          setTermID(term.termID);
        };

        return (
          <li className="nav-item" key={term.termID} role="button">
            <span
              className={`nav-link ${isActive} me-2`}
              onClick={setTermIDHandler}
            >
              <span>{term.termName}</span>
            </span>
          </li>
        );
      })}
    </ul>
  );
};

interface AddCourseProps {
  inputValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddCourse = ({ inputValue, onChange }: AddCourseProps) => {
  return (
    <div className="pt-2">
      <label htmlFor="addCourseInput">Add Course</label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Your Course Name (Ex. MATH 151)"
        id="addCourseInput"
        value={inputValue}
        onChange={onChange}
      />
    </div>
  );
};

interface AddYourCoursesProps {
  userDoc: UserDoc;
}

//TODO - ALL DONE!
//DONE migrate the code from the AddYourCourses component to a function in AppState
//DONE refactor the code in AppState such that there are functions to set the termsCaches
//DONE and there are functions in AppState to get the data for a termsCache for a specific school
//DONE move the fetching logic to AppState, and pass in the function that modulates the local loading state

const AddYourCourses = ({ userDoc }: AddYourCoursesProps) => {
  const { termsCacheTwo, loadTermsCacheTwo, termsCacheTwoLoaded } =
    React.useContext(AppContext);
  const { addUserCourse } = React.useContext(UserContext);

  const [currTermID, setCurrTermID] = React.useState<string>(
    userDoc.currentTermID
  );
  const [courseNameInput, setCourseNameInput] = React.useState<string>("");
  const [filteredCourses, setFilteredCourses] = React.useState<
    SchoolTermCacheTwoCourseData[]
  >([]);

  const cacheTwoLoaded = termsCacheTwoLoaded(
    termsCacheTwo[userDoc.schoolID],
    currTermID
  );

  React.useEffect(() => {
    if (!cacheTwoLoaded) {
      loadTermsCacheTwo(userDoc.schoolID, currTermID);
    }
  }, [currTermID]);

  React.useEffect(() => {
    //Set Filtered Courses
    setFilteredCourses((st) => {
      //if our input is empty, show no filtered courses
      if (courseNameInput === "") {
        return [];
      } else {
        const currTermObj = termsCacheTwo[userDoc.schoolID].filter(
          (term) => term.termID === currTermID
        );
        const userDocTerm = userDoc.terms.filter(
          (t) => t.termID === currTermID
        );

        if (currTermObj.length === 0) {
          //we haven't fetched this term's courses from server yet
          //so we can't show any filtered courses
          return [];
        } else if (currTermObj.length === 1) {
          //we fetched this term's courses from already
          //so show the filtered courses
          const matchedCourses = currTermObj[0].courses.filter((course) => {
            const matchedName = course.name
              .toUpperCase()
              .includes(courseNameInput.toUpperCase());
            return matchedName;
          });

          let withoutAlreadyAddedCourses;
          if (userDocTerm.length === 0) {
            withoutAlreadyAddedCourses = matchedCourses;
          } else if (userDocTerm.length === 1) {
            const alreadyAddedCourses = userDocTerm[0].courses.map((c) => c.ID);
            withoutAlreadyAddedCourses = matchedCourses.filter(
              (c) => !alreadyAddedCourses.includes(c.ID)
            );
          } else {
            throw "Found more than one term on user doc with the same id";
          }

          return withoutAlreadyAddedCourses.slice(0, 10);
        } else {
          //we matched multiple courses
          throw "Something went wrong, two terms with same id";
        }
      }
    });
  }, [courseNameInput]);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCourseNameInput(e.target.value);
  };

  const selectCourseHandler = (termID: string, courseID: string) => {
    //clear the user input
    setCourseNameInput("");
    //call addCourse
    addUserCourse(currTermID, courseID);
  };

  const autofillDropdown =
    filteredCourses.length > 0 ? (
      <div
        className={
          filteredCourses.length > 0 ? `mt-3 border rounded shadow-sm` : ""
        }
      >
        {filteredCourses.map((c, i) => {
          return (
            <div
              className={`py-2 px-2 ${
                i === filteredCourses.length - 1 ? "" : "border-bottom"
              }`}
              key={c.ID}
              onClick={() => selectCourseHandler(currTermID, c.ID)}
              style={{ cursor: "pointer" }}
            >
              {c.name}
            </div>
          );
        })}
      </div>
    ) : (
      <div></div>
    );

  return (
    // <div className="card mt-3">
    //   <div className="card-body">
    //     <h4>Add Your Courses</h4>
    <div>
      <SetTerm
        currTermID={currTermID}
        allTerms={userDoc.terms}
        setTermID={(newTermID) => setCurrTermID(newTermID)}
      />
      <AddCourse inputValue={courseNameInput} onChange={onChange} />
      {autofillDropdown}
    </div>
  );

  // </div>
  // </div>
};

export default AddYourCourses;
