import * as React from "react";

import { UserContext } from "../../UserState";
import { UserDocSchoolTermData } from "../../models";

import DeleteIcon from "@mui/icons-material/Delete";

interface CoursesAlreadyAddedProps {
  terms: UserDocSchoolTermData[];
}

const CoursesAlreadyAdded = ({ terms }: CoursesAlreadyAddedProps) => {
  const { deleteUserCourse } = React.useContext(UserContext);
  const termsWithCourses = terms.filter((t) => t.courses.length > 0);

  const termsWithoutCourses = terms.filter((t) => t.courses.length === 0);

  const deleteHandler = (termID: string, courseID: string) => {
    deleteUserCourse(termID, courseID);
  };

  return (
    <div>
      <div>
        {terms.map((term, termIndex) => {
          if (term.courses.length === 0) {
            return (
              <div className="py-2" key={term.termID}>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <h5 className="card-title mb-0">{term.termName}</h5>
                  <small className="text-muted">Add Courses Above</small>
                </div>
              </div>
            );
          }
          return (
            <div
              className={`py-2 ${
                termIndex === termsWithCourses.length - 1 ? "" : "mb-2"
              }`}
              key={term.termID}
            >
              <h5 className="card-title mb-2">{term.termName}</h5>
              <div>
                {term.courses.map((c, i) => {
                  const isLast = i === term.courses.length - 1;
                  const bottomMargin = isLast ? "" : "mb-2";
                  return (
                    <div className={`card ${bottomMargin}`} key={c.ID}>
                      <div className="py-2 px-2">
                        <div className="d-flex flex-row align-items-center">
                          <div className="flex-fill d-flex flex-column justify-content-center">
                            <p className="card-title mb-0">{c.name.fullName}</p>
                            <small className="text-muted mb-0">
                              {c.fullTitle}
                            </small>
                          </div>
                          <button className="btn border text-dark px-2">
                            <DeleteIcon
                              className="text-dark"
                              onClick={() => deleteHandler(term.termID, c.ID)}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CoursesAlreadyAdded;
